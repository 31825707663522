import {ALL_MESSAGES, SEND_CONTACT_MESSAGE, SUBSCRIBE, WRITE_QUOTE } from '../constants/actionTypes';
const reducerContactMessages = ( state = {contactmessages: [], subscriptions: []},/*state=[]posts=[]*/ action) => {
   switch(action.type) {
    
    case ALL_MESSAGES:
        return {
            ...state, 
            contactmessages: action.payload.data};    

        case SEND_CONTACT_MESSAGE:
        return {
            ...state, 
            contactmessages: [...state.contactmessages, action.payload] };    
           
        case WRITE_QUOTE:
        return {
            ...state, 
            contactmessages: [...state.contactmessages, action.payload] };    
        
        /* case SUBSCRIBE:
        return {
            ...state, 
            subscriptions: [...state.subscriptions, action.payload] };    
 */        
        default:
        return state;
        //break;
   }
}

export default reducerContactMessages;