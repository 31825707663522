import React, {useState} from 'react'

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import HomeCarousel from './HomeCarousel';
import AccordionHome from '../Accordions/AccordionHome';
import FixedSideBar from '../FixedSideBar';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Accordion from 'react-bootstrap/Accordion';
import logo from '../../images/policydirectlogo.png'

import './TouristVisa.css';
import './Home.css';
import ContactUsModal from '../Modals/ContactUsModal';
const TemporaryGraduateVisa = () => {
  //modal js
 
  const [showModal, setShowModal] = useState(false);

  const openContactModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  //
  return (
    <>
    {/* <FixedSideBar/> */}
    
    <main style={{width: '100%', height: 'auto'}} className="text-secondary">
        
        <Card className="text-center">
            {/* <Card.Header className='p-5' style={{marginTop: '0', marginBottom: '0', paddingTop: '0', paddingBottom: '0'}}>
                <h4 style={{marginTop: '0', marginBottom: '0', paddingTop: '0', paddingBottom: '0'}}>Policy Direct Australia</h4>
            </Card.Header> */}
            <Card.Body className='p-5'>                
                <Card.Text>

                 
                      <h6 style={{color: 'black'}}>Temporary Graduate Visa (subclass 485)</h6>

                <p style={{color: 'black', textAlign: 'left'}}>
                <strong>Temporary Graduate Visa (subclass 485): </strong><br/>
                The Temporary Graduate visa (subclass 485) is for the recent international graduates who have completed at least 2 years of study from a CRICOS registered Australian educational institution.
                <br/><br/>
                <strong>The visa is divided into two streams:</strong><br/>
                <br/>
                <strong>Graduate Work Stream:</strong><br/>
                A visa in this stream is valid for 18 months and applicable to those international students who have:
                <br/><br/>

                <ul>
                    <li>Finished 2 years of study in Australia </li>
                    <li>Occupation listed on medium and long term Strategic Skill List of Department of Home Affairs</li>
                    <li>A relevant skill assessment in that occupation</li>
                </ul>
               <br/>
               <strong>Post-Study Work Stream: </strong><br/>
               A visa in this stream is valid up to 4 years and applicable to those international students who have:
               <br/><br/>
               <ul>
                <li>Hold an Australian student visa after 5th November 2011.</li>
                <li>Hold a Bachelor, Master, or Doctorate degree from an Australian education provider irrespective of their field of study.</li>
               </ul>
                </p>
                <h6 style={{color: 'black'}}>Why Aussie New Zea Migration?</h6>
                <p style={{color: 'black', textAlign: 'left'}}>
                Aussie New Zea Migration can guide you in the right direction. We have an extensive experience of filling up applications with appropriate documents without any error. This saves a lot of your time and energy. You can contact us or {/* <a href='/contactus'>Request a call back</a>. */}
                <br/><br/>
                <Button onClick={openContactModal} className="blinking-btn" size='sm' style={{backgroundColor: "#064CAC", boxShadow: '1px 1px 1px #EC373C', color: '#EC373C', border: 'none'}}><a style={{textDecoration: 'none', color: 'whitesmoke'}}><strong>Request a Call Back</strong></a></Button>              


                <br/><br/>
                <strong>Disclaimer: </strong> ‘Aussie New Zea Migration & Education Consultancy’ and its associates are independent consulting entities which are not associated in anyway with the Australian ‘Department of Home Affairs’ (DOHA). Information on this website does not constitute personal migration advice. For a customized migration advice based on your personal circumstances, please <a href="tel:+61479070270">call</a> and talk to one of our Immigration Consultants or register your interest with our Associates.

                </p>
                
               
            </Card.Text>
            </Card.Body>
            <Card.Footer className="text-muted p-5 mb-0"></Card.Footer>

            
    </Card>
    <ContactUsModal
        showModal={showModal} 
        closeModal={closeModal} 
      />
    </main>

    </>
  )
}

export default TemporaryGraduateVisa
