import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './OverseasVisitorHealthCoverPageCss.css'
import Button from 'react-bootstrap/Button';

const OverseasVisitorHealthCoverPage = () => {
  return (
    <>
        <Container>
        <Row>
          <Col>
            <div className='ovhc-div-with-background-image' >
                <br/><br/><br/><br/>
                <h1 style={{color: '#000080'}}>Overseas Visitor Health Cover</h1>
                <br/><br/>
                <h1 style={{color: '#000080'}}>
                    As experts in International Health Insurance, we are committed to making your journey to Australia simple and easy.
                </h1>
                <br/><br/>
                <a href='/visitorvisacoveroptions'>
                <Button variant="danger">Get A Price</Button>
                </a>

            </div>
            <br/>
          </Col>
        </Row>
        </Container>          

    </>    
    )
}

export default OverseasVisitorHealthCoverPage