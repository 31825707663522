import React, {useState} from 'react'

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import HomeCarousel from './HomeCarousel';
import AccordionHome from '../Accordions/AccordionHome';
import FixedSideBar from '../FixedSideBar';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Accordion from 'react-bootstrap/Accordion';
import logo from '../../images/policydirectlogo.png'


import './Home.css';
import './TouristVisa.css';
import ContactUsModal from '../Modals/ContactUsModal';
const TouristVisa = () => {

  //modal js
 
  const [showModal, setShowModal] = useState(false);

  const openContactModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <>
    {/* <FixedSideBar/> */}
    
    <main style={{width: '100%', height: 'auto'}} className="text-secondary">
        
        <Card className="text-center">
            {/* <Card.Header className='p-5' style={{marginTop: '0', marginBottom: '0', paddingTop: '0', paddingBottom: '0'}}>
                <h4 style={{marginTop: '0', marginBottom: '0', paddingTop: '0', paddingBottom: '0'}}>Policy Direct Australia</h4>
            </Card.Header> */}
            <Card.Body className='p-5'>                
                <Card.Text>

                
                <h6 style={{color: 'black'}}>Tourist Visa</h6>
                <p style={{color: 'black', textAlign: 'left'}}>
                <strong>Tourist Visa (subclass 600): </strong><br/>
                Tourist visa is for people who wish to visit Australia. Visit may include vacationing, recreational activities, visiting an Australian resident family member, medical treatment etc. <strong>This visa is meant for visit or for business purposes for up to three, six or twelve months.</strong>
                <br/><br/>
                <strong>Other two sub-classes are 601 and 651: </strong><br/>
                <ul>
                    <li>The eVisitor (sub-class 651): This visa is free. You must be a passport holder of a certain country as specified in a list issued by Department of Home Affairs.</li>
                    <li>The Electronic Travel Authority (subclass 601) : This is issued to a passport holder of a certain country as specified in a list issued by Department of Home Affairs.</li>
                    <li>Join our Facebook conversation on Tourist visa – <a href='https://www.facebook.com/groups/3129241637330971'>https://www.facebook.com/groups/3129241637330971</a></li>
                    <li>Join our Facebook conversation on ETA (subclass 601, 651) – <a href='https://www.facebook.com/groups/1379443959233480'>https://www.facebook.com/groups/1379443959233480</a> </li>
                </ul>
               
                </p>
                <h6 style={{color: 'black'}}>Why Aussie New Zea Migration?</h6>
                <p style={{color: 'black', textAlign: 'left'}}>
                Aussie New Zea Migration can guide you in the right direction. We have an extensive experience of filling up applications with appropriate documents without any error. This saves a lot of your time and energy. You can contact us or 
                <br/><br/>
                <Button onClick={openContactModal} className="blinking-btn" size='sm' style={{backgroundColor: "#064CAC", boxShadow: '1px 1px 1px #EC373C', color: '#EC373C', border: 'none'}}><a style={{textDecoration: 'none', color: 'whitesmoke'}}><strong>Request a Call Back</strong></a></Button>              
                <br/><br/>
                <strong>Disclaimer: </strong> ‘Aussie New Zea Migration & Education Consultancy’ and its associates are independent consulting entities which are not associated in anyway with the Australian ‘Department of Home Affairs’ (DOHA). Information on this website does not constitute personal migration advice. For a customized migration advice based on your personal circumstances, please <a href="tel:+61479070270">call</a> and talk to one of our Immigration Consultants or register your interest with our Associates.

                </p>
                
                              
                {/* <Button style={{backgroundColor: "#8D021F", border: 'none'}}><a href='/contactus' style={{textDecoration: 'none', color: 'white'}}>Get Quote</a></Button>
 */}
                {/* <HomeCarousel/> */}
                 
            </Card.Text>
            </Card.Body>
            <Card.Footer className="text-muted p-5 mb-0"></Card.Footer>

            
    </Card>
    <ContactUsModal
        showModal={showModal} 
        closeModal={closeModal} 
      />
    </main>

    </>
  )
}

export default TouristVisa
