import React, {useState} from 'react'

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import HomeCarousel from './HomeCarousel';
import AccordionHome from '../Accordions/AccordionHome';
import FixedSideBar from '../FixedSideBar';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Accordion from 'react-bootstrap/Accordion';
import logo from '../../images/policydirectlogo4.jpeg'
import allianzCareLogo from '../../images/allianzcarelogo.png'
import bupaLogo from '../../images/bupalogo.png'
import medibankLogo from '../../images/medibanklogo.png'
import nibLogo from '../../images/nibLogo.png'
//import ahmLogo from '../../images/ahmLogo.jpg'
import Table from 'react-bootstrap/Table';
//import Container from 'react-bootstrap/Container';
//import Row from 'react-bootstrap/Row';
//import Col from 'react-bootstrap/Col';
import { GoPaperAirplane } from "react-icons/go";
import { GoTriangleRight } from "react-icons/go";
import './Home.css';
import ContactUsModal from '../Modals/ContactUsModal';
const Faqs = () => {

  //modal js
 
  const [showModal, setShowModal] = useState(false);

  const openContactModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  //
  return (
    <>
    {/* <FixedSideBar/> */}
    
    <main style={{width: '100%', height: 'auto'}} className="text-secondary">
        
        <Card className="text-center">
            {/* <Card.Header className='p-5' style={{marginTop: '0', marginBottom: '0', paddingTop: '0', paddingBottom: '0'}}>
                <h4 style={{marginTop: '0', marginBottom: '0', paddingTop: '0', paddingBottom: '0'}}>Policy Direct Australia</h4>
            </Card.Header> */}
            <Card.Body className='p-5'>                
                <Card.Text>
                {/* <h6 style={{color: 'black'}}>FAQS</h6> */}
                      <Accordion className='accordion-faqs dark'  /* defaultActiveKey="0" */ flush /* style={{fontSize: '.7em'}}  */>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header className='custom-header' style={{backgroundColor: 'red', fontSize: '.7em', fontWeight: 'bold'}}><strong style={{color: 'black', fontSize: '.8em'}}>Allianz Care Australia Overseas Visitor Health Cover (OVHC){/* (small font color black, bold) */}</strong></Accordion.Header>
                          <Accordion.Body>
                            <p style={{textAlign: 'left', fontSize: '.8em'}}>
                            {/* (make sure font is small and justify) */}<strong><GoTriangleRight className='go-paper-airplane right-arrow-symbol'/></strong> Allianz Care Australia is one of the providers in Australia that is famous for its Overseas Visitor Health Cover (OVHC) and Overseas Student Health Cover (OSHC). 
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> Either you come on student visa (subclass500) or on a visitor visa (subclass 600), Allianz care Australia is the company that meets 8501 visa condition of the Department of Home Affairs, Australia.
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> Allianz Care Australia has many facilities such as Find a doctor wherein you can find a doctor near your location. Allianz Care Australia has a facility of Telehealth powered by Doctors on Demand wherein you can talk to a Doctor from the comfort of your Home. Allianz Care Australia members (excluding Budget Working cover and Budget Visitors Cover) can speak to a qualified Australian based doctor 24/7 via phone or video call.
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> Hence, Allianz Care Overseas Visitor Health Cover (OVHC) is well in demand in Australia.
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> For international students, Allianz Care Australia has a facility of my Health app where in students can 
                            <br/><br/>
                            <ul>
                              <li>(rather than dots, make arrows in all of them)</li>
                              <li>Access digital membership card</li>
                              <li>Submit or check the status of medical claim</li>
                              <li>View policy details</li>
                            </ul>
                            
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> Allianz Care Australia provides excellent Help & Support for claims.
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> To get Allianz Care Overseas Visitor Health Cover (OVHC), you can check <br/><a href='https://payment.ovhcallianzassistance.com.au/en/?agencyID=856437&groupCodeID=A600'>Overseas Visitor Health Cover (OVHC)</a>:
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> To get Allianz Care Overseas Student Health Cover (OSHC), you can check <br/><a href='https://api.allianz.com/myquote/1?groupId=56437'>Overseas Student Health Cover (OSHC)</a>:
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> Allianz Care Australia can be contacted at:
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> {/* <IoIosArrowForward/> */}{/* (design this arrow and keep arrow in each paragraph) */}For Overseas Visitor Health Cover (OVHC), you can email at
                            <br/>
                            <a href='mailto:ovhc@allianzcare.com.au'>ovhc@allianzcare.com.au</a> or 
                            
                            call on <a href="tel:1300 727 193">1300 727 193</a> 
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> For Overseas Student Health Cover, you can email at 
                            <br/>
                            <a href='mailto:oshc@allianzcare.com.au'>oshc@allianzcare.com.au</a> 
                            <br/>
                            or
                            <br/>
                            call on <a href='tel:13 67 42'>13 67 42</a> 
                            <br/><br/>
                            <strong><GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> To get Overseas Student Health Cover (OSHC) and/or Overseas Visitor Health Cover (OVHC), you can also {/* (make all of those bold) */} </strong>
                            <br/><br/>
                            <Button onClick={openContactModal} className="blinking-btn" size='sm' style={{backgroundColor: "#064CAC", boxShadow: '1px 1px 1px #EC373C', color: '#EC373C', border: 'none'}}><a style={{textDecoration: 'none', color: 'whitesmoke'}}><strong>Request a Call Back</strong></a></Button>              
                         </p>
                          </Accordion.Body>
                        </Accordion.Item>                        

                        <Accordion.Item eventKey="1">
                          <Accordion.Header style={{fontSize: '.7em'}}><strong style={{color: 'black', fontSize: '.8em'}}>What is the 8501 Visa Condition?</strong></Accordion.Header>
                          <Accordion.Body>
                            <p style={{textAlign: 'left', fontSize: '.8em'}}>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> The Australian government's immigration website says that "you must have and maintain adequate health insurance for the whole of your stay in Australia" if you are subject to the 8501-visa condition.
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> Most Australian visas come with the 8501-visa condition. This means you need to purchase health insurance if you are coming to Australia. There are many Australian insurance companies/providers that offer their cover which meets 8501 visa condition of the Department of Home Affairs, Australia. 
                            <br/><br/>
                            <strong><GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> How to meet the 8501-visa condition of the Department of Home Affairs?</strong>
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> It depends on the subclass of your visa that you are coming to Australia. For example, if you are coming to Australia on visitor visa (Subclass 600) which has 8501 visa condition, then, there are two providers which meet 8501 visa condition of the Department of Home Affairs, Australia. You can check <a href='https://payment.ovhcallianzassistance.com.au/en/?agencyID=856437&groupCodeID=A600'>(open a modal with ovhc and all three buttons same as navbar/ open the /visitorvisacoveroptions)</a>:  
                            <br/>                                  and see the Visitor Visa cover options.
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> On the other hand, If you are coming to Australia on working visas such as subclass 400, 407, 408, 417, 462, 476, 482, 485, then you can check <a href='/workingvisacoveroptions'>here</a>                                                                and see the working visa cover options.<br/>
                            <br/>                            
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> If you are coming to Australia on non-working visa such as Sponsored Parent Visa (Subclass 870) or have any Bridging visa, then, you can check <a href='/nonworkingvisacoveroptions'>here</a> and                        see the non-working visa cover options.
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> You can meet the 8501-visa condition by taking the required health insurance by purchasing Overseas Visitor Health Cover (OVHC).
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> You can see the comparison of all the Australian providers such as Allianz Care Australia, BUPA, Medibank, NIB, and Australian Health Management.
                            <br/><br/>
                            <strong><GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> To get Overseas Student Health Cover (OSHC) and/or Overseas Visitor Health Cover (OVHC), you can also </strong>
                            <br/><br/>
                            <Button onClick={openContactModal} className="blinking-btn" size='sm' style={{backgroundColor: "#064CAC", boxShadow: '1px 1px 1px #EC373C', color: '#EC373C', border: 'none'}}><a style={{textDecoration: 'none', color: 'whitesmoke'}}><strong>Request a Call Back</strong></a></Button>              


                            </p>
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                          <Accordion.Header style={{fontSize: '.7em'}}><strong style={{color: 'black', fontSize: '.8em'}}>What is Overseas Visitors health cover (OVHC)</strong></Accordion.Header>
                          <Accordion.Body>
                            <p style={{textAlign: 'left'}}>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> Overseas visitors’ health cover (OVHC) is a health insurance which is designed specifically for the people coming to live and work in Australia on a visa. If this visa has a 8501 visa condition, then it’s mandatory to get the Overseas Visitors Health Cover (OVHC) from a provider who meets 8501 visa condition such as Allianz Care Australia, BUPA, Medibank, NIB, AHM etc.
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> <strong>Overseas visitors’ health cover (OVHC) can cover the following:</strong>
                            <br/><br/>
                            <ul>
                              <li>Hospitalisation</li>
                              <li>Emergency Ambulance</li>
                              <li>Doctor’s Appointments</li>
                              <li>Medical Tests</li>
                              <li>Prescribed Medicines</li>
                              <li>Repatriation</li>
                            </ul>
                            
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> Allianz Care Overseas Visitor Health Cover (OVHC) has many cover options which can be checked and compared with other providers such as BUPA, Medibank, and NIB.
                            <br/><br/>
                            <strong><GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> To get Overseas Student Health Cover (OSHC) and/or Overseas Visitor Health Cover (OVHC), you can also </strong>

                            <br/><br/>
                            <Button onClick={openContactModal} className="blinking-btn" size='sm' style={{backgroundColor: "#064CAC", boxShadow: '1px 1px 1px #EC373C', color: '#EC373C', border: 'none'}}><a style={{textDecoration: 'none', color: 'whitesmoke'}}><strong>Request a Call Back</strong></a></Button>              

                            </p>
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                          <Accordion.Header style={{fontSize: '.7em'}}><strong style={{color: 'black', fontSize: '.8em'}}>What is Reciprocal Health Care Agreements (RHCA)</strong></Accordion.Header>
                          <Accordion.Body>
                            <p style={{textAlign: 'left'}}>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/>Australia has arrangements with eleven countries—called Reciprocal Health Care Agreements (RHCA) : to allow most visitors from these eleven countries to use the Australian / Medicare public health system and in return, allow Australians to access medically necessary care in these 11 countries.
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/><strong>The RHCA is open to most visitors from: </strong>
                            <br/><br/>
                            <ul>
                              <li>Belgium</li>
                              <li>Finland</li>
                              <li>Italy</li>
                              <li>Malta</li>
                              <li>Netherlands</li>
                              <li>New Zealand</li>
                              <li>Norway</li>
                              <li>Republic of Ireland</li>
                              <li>Slovenia</li>
                              <li>Sweden</li>
                              <li></li>
                            </ul>
                            <br/>
                            <strong><GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> Information for visitors coming to Australia on Reciprocal Health Care Agreements:</strong>
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> Here is the useful link of Services Ausytralia: <a href='https://www.servicesaustralia.gov.au/when-reciprocal-health-care-agreements-apply-and-you-visit-australia?context=22481'>When reciprocal health care agreements apply and you visit Australia - Reciprocal Health Care Agreements - Services Australia</a>
                            <br/><br/>
                            <strong><GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> To get Overseas Student Health Cover (OSHC) and/or Overseas Visitor Health Cover (OVHC), you can also </strong>


                            <br/><br/>
                            <Button onClick={openContactModal} className="blinking-btn" size='sm' style={{backgroundColor: "#064CAC", boxShadow: '1px 1px 1px #EC373C', color: '#EC373C', border: 'none'}}><a style={{textDecoration: 'none', color: 'whitesmoke'}}><strong>Request a Call Back</strong></a></Button>              

                            </p>
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                          <Accordion.Header style={{fontSize: '.7em'}}><strong style={{color: 'black', fontSize: '.8em'}}>600 Visa Health Insurance</strong></Accordion.Header>
                          <Accordion.Body>
                            <p style={{textAlign: 'left'}}>
                            <strong><GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> Tourist Visa (subclass 600)</strong>
                            <br/>
                            
                            Tourist visa is for people who wish to visit Australia. Visit may include vacationing, recreational activities, visiting an Australian resident family member, medical treatment etc. <strong>This visa is meant for visit or for business purposes for up to three, six or twelve months. </strong>
                             
                            <br/><br/>
                            <strong><GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> Other two sub-classes are 601 and 651:</strong>
                            <br/>
                            <ul>
                              <li>
                                The eVisitor (sub-class 651): This visa is free. You must be a passport holder of a certain country as specified in a list issued by Department of Home Affairs.
                              </li><br/>
                              <li>
                                The Electronic Travel Authority (subclass 601) : This is issued to a passport holder of a certain country as specified in a list issued by Department of Home Affairs.
                              </li>
                            </ul>
                            <br/>
                            <strong><GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> Are you travelling to Australia on a visitor visa (subclass 600)?</strong>
                            <br/><br/>
                            <strong>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> If you are coming to Australia on a holiday, or for a short business trip or staying with family, you can get Overseas Visitor Health Cover (OVHC) from
                            <br/>
                            <a href='https://payment.ovhcallianzassistance.com.au/en/?agencyID=856437&groupCodeID=A600'>(open modal for ovhc)</a>
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> Allianz Care Overseas Visitor Health Cover (OVHC) is one of the best Overseas Visitor Health Cover available in Australia. Allianz OVHC is valid Australia-wide and lets you access medical and health cover if something happens during your stay. The Department of Home Affairs recommends that 600 visa holders take out health insurance each time you travel to Australia – so that you are covered during your stay.
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> Allianz Care Australia has two options for persons who are on Visitor visa (subclass 600).
                                                        
                            </strong>
                            <br/><br/>
                            <Table striped bordered hover size="sm">
                              <thead>
                                <tr>                                  
                                  <th>Budget Visitor Cover</th>
                                  <th>Standard visitor Cover</th>                                  
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                 
                                  <td><a href='https://payment.ovhcallianzassistance.com.au/en/?agencyID=856437&groupCodeID=A600'>AUD 135.15 per month</a></td>
                                  <td><a href='https://payment.ovhcallianzassistance.com.au/en/?agencyID=856437&groupCodeID=A600'>AUD 297.90 per month</a></td>
                                  
                                </tr>
                               
                              </tbody>
                            </Table>

                            {/* <br/>
                            You can purchase the policy from <a href=''>here</a>
                            <br/> */}
                            <strong><GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> Still unsure what to do? You can also</strong><br/><br/>
                            <Button onClick={openContactModal} className="blinking-btn" size='sm' style={{backgroundColor: "#064CAC", boxShadow: '1px 1px 1px #EC373C', color: '#EC373C', border: 'none'}}><a style={{textDecoration: 'none', color: 'whitesmoke'}}><strong>Request a Call Back</strong></a></Button>              

                            </p>
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5">
                          <Accordion.Header style={{fontSize: '.7em'}}><strong style={{color: 'black', fontSize: '.8em'}}>870 Visa Health Insurance</strong></Accordion.Header>
                          <Accordion.Body>
                            <p style={{textAlign: 'left'}}>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> Are you travelling to Australia on a Sponsored Parent Visa (subclass 870)?
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> The Australian Sponsored Parent Visa (subclass 870) is a temporary visa that is issued to the parents of Australian citizens and/or Permanent residents, allowing them to live in Australia with their children for a maximum of TEN years.
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> Visa officer may request the visa applicant to get Overseas Visitors Health Cover (OVHC). 
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> Allianz Care Overseas Visitor Health Cover (OVHC) is one of the best Overseas Visitor Health Cover available in Australia. Allianz OVHC is valid Australia-wide and lets you access medical and health cover if something happens during your stay. The Department of Home Affairs recommends that 600 visa holders take out health insurance each time you travel to Australia – so that you are covered during your stay.
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> Allianz Care Australia has two options for persons who are on Visitor visa (subclass 600).
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> Allianz Care Australia has two options for persons who are on Visitor visa (subclass 600).
 
                            <br/><br/>
                            <Table striped bordered hover size="sm">
                              <thead>
                                <tr>                                  
                                  <th>Budget Visitor Cover</th>
                                  <th>Standard visitor Cover</th>                                  
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                 
                                  <td><a href='https://payment.ovhcallianzassistance.com.au/en/?agencyID=856437&groupCodeID=A600'>AUD 135.15 per month</a></td>
                                  <td><a href='https://payment.ovhcallianzassistance.com.au/en/?agencyID=856437&groupCodeID=A600'>AUD 297.90 per month</a></td>
                                  
                                </tr>
                               
                              </tbody>
                            </Table>
<br/>
                            {/* <br/>
                            You can purchase the policy from <a href=''>here</a>
                            <br/> */}
                            <strong><GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> BUPA is one of other providers for Overseas Visitors Health Cover (OVHC). BUPA policy can be purchased from the link <a href='https://bupa.com.au/migration-agents/iodnqjq5yeesnvze4iyaug'>here</a></strong>
                            <br/><br/>
                            <strong>Still unsure what to do? You can also</strong><br/><br/>
                            <Button onClick={openContactModal} className="blinking-btn" size='sm' style={{backgroundColor: "#064CAC", boxShadow: '1px 1px 1px #EC373C', color: '#EC373C', border: 'none'}}><a style={{textDecoration: 'none', color: 'whitesmoke'}}><strong>Request a Call Back</strong></a></Button>              


                            
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="6">
                          <Accordion.Header style={{fontSize: '.7em'}}><strong style={{color: 'black', fontSize: '.8em'}}>485 Visa Health Insurance</strong></Accordion.Header>
                          <Accordion.Body>
                            <p style={{textAlign: 'left'}}>
                              <strong>
                              <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> Are you recently graduating from your university ?
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> Do you need to lodge your 485 visa and need health insurance ?
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> The Department of Home Affairs requires that 485 visa holders take out health insurance each time they travel to Australia – so they are covered during their stay.
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> We provide best comparison for taking 485 visa insurance below
                            <br/>
                             <a href='/workingvisacoveroptions'>Click here</a>
                            </strong>

                            <br/><br/>
                            <strong><GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> To get Overseas Student Health Cover (OSHC) and/or Overseas Visitor Health Cover (OVHC), you can also </strong>

                            <br/><br/>
                            <Button onClick={openContactModal} className="blinking-btn" size='sm' style={{backgroundColor: "#064CAC", boxShadow: '1px 1px 1px #EC373C', color: '#EC373C', border: 'none'}}><a style={{textDecoration: 'none', color: 'whitesmoke'}}><strong>Request a Call Back</strong></a></Button>              

                            </p>
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="7">
                          <Accordion.Header style={{fontSize: '.7em'}}><strong style={{color: 'black', fontSize: '.8em'}}>Pre existing conditions and Waiting periods</strong></Accordion.Header>
                          <Accordion.Body>
                            <p style={{textAlign: 'left'}}>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> A pre-existing condition is an ailment, illness or condition, the signs or symptoms of which, in the opinion of our appointed medical practitioner, existed at any time in the period of six months prior to you becoming insured under the policy.
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/>Waiting periods:
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> A waiting period is the time you need to wait after purchasing your policy and before cover is available for certain medical conditions. You cannot claim for medical treatment that is provided during waiting period. This means that you will need to pay for all expenses for the treatment.
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> Certain medical conditions and treatments have different waiting periods. You must be aware about waiting periods. If you are unsure you can

                            <br/><br/>
                            <strong><GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> To get Overseas Student Health Cover (OSHC) and/or Overseas Visitor Health Cover (OVHC), you can also </strong>

                            <br/><br/>
                            <Button onClick={openContactModal} className="blinking-btn" size='sm' style={{backgroundColor: "#064CAC", boxShadow: '1px 1px 1px #EC373C', color: '#EC373C', border: 'none'}}><a style={{textDecoration: 'none', color: 'whitesmoke'}}><strong>Request a Call Back</strong></a></Button>              

                            </p>
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="8">
                          <Accordion.Header style={{fontSize: '.7em'}}><strong style={{color: 'black', fontSize: '.8em'}}>What is Overseas Student Health Insurance (OSHC)?</strong></Accordion.Header>
                          <Accordion.Body>
                            <p style={{textAlign: 'left'}}>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> International students undertaking formal studies in Australia, and their dependents (for example, spouses, and children under 18 years old), must obtain OSHC. It includes cover for visits to the doctor, some hospital treatment, ambulance cover and limited pharmaceuticals (medicines).
                            <br/><br/>
                            <strong><GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> How can I get my OSHC?</strong>
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> You can get your Overseas Student Health Cover (OSHC) from <a href='https://api.allianz.com/myquote/1?groupId=56437'>here</a>
                            <br/><br/>
                            or
                            <br/><br/>
                            <strong><GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> To get Overseas Student Health Cover (OSHC) and/or Overseas Visitor Health Cover (OVHC), you can also </strong>

                            <br/><br/>
                            <Button onClick={openContactModal} className="blinking-btn" size='sm' style={{backgroundColor: "#064CAC", boxShadow: '1px 1px 1px #EC373C', color: '#EC373C', border: 'none'}}><a style={{textDecoration: 'none', color: 'whitesmoke'}}><strong>Request a Call Back</strong></a></Button>              


                           
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>

                                        
                      </Accordion>                    
                   
                </Card.Text>
            </Card.Body>
            <Card.Footer className="text-muted p-5 mb-0"></Card.Footer>

            
    </Card>
    <ContactUsModal 
        showModal={showModal} 
        closeModal={closeModal} 
      />
    </main>
    
    </>
  )
}

export default Faqs
