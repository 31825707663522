import React, {useState} from 'react'

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import HomeCarousel from './HomeCarousel';
import AccordionHome from '../Accordions/AccordionHome';
import FixedSideBar from '../FixedSideBar';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Accordion from 'react-bootstrap/Accordion';
import logo from '../../images/policydirectlogo4.jpeg'
import allianzCareLogo from '../../images/allianzcarelogo.png'
import bupaLogo from '../../images/bupalogo.png'
import medibankLogo from '../../images/medibanklogo.png'
import nibLogo from '../../images/nibLogo.png'
//import ahmLogo from '../../images/ahmLogo.jpg'
import ahmLogo from '../../images/ahmLogo.png'
//import Container from 'react-bootstrap/Container';
//import Row from 'react-bootstrap/Row';
//import Col from 'react-bootstrap/Col';
//import backgroundImage1 from '../../images/backgroundImage1.jpg'
import './Home.css';
import ContactUsModal from '../Modals/ContactUsModal';
import { BiSolidPhoneCall } from "react-icons/bi";
import { GoTriangleRight } from "react-icons/go";
import ClientsFeedback from './ClientsFeedback';
const Home = () => {
  //const backgroundImageUrl1 = //null
  
  const containerStyle1 = {
    backgroundImage: 'url("../../images/ahmLogo.png")',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '400px' // Set your desired height

  };


  //modal js
 
  const [showModal, setShowModal] = useState(false);

  const openContactModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  //

  return (
    <>
    {/* <FixedSideBar/> */}
    
    <main style={{width: '100%', height: 'auto'}} className="text-secondary">
    <br/>
    <h4>
     <span className='text-info'>Health insurance for</span> <span className='text-primary'>international students,</span><span className='text-danger'> workers and visitors</span>
    </h4>
    <br/>
    <Container>
        <Row>
          <Col sm={6}>           
            <h5 className='text-secondary'><a className='anchor-tag' href='https://api.allianz.com/myquote/1?groupId=56437'>Allianz Care Overseas Student Health Cover</a></h5>
            <a href='https://api.allianz.com/myquote/1?groupId=56437'>
            <div className='home-page-div-one' ></div>
            </a>
            <br/>
            <strong style={{color: 'black', fontSize: '1em'}}>What is Overseas Student Health Cover (OSHC)?</strong>
            <p style={{textAlign: 'left'}}>                            
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> International students undertaking formal studies in Australia, and their dependents (for example, spouses, and children under 18 years old), must obtain OSHC. It includes cover for visits to the doctor, some hospital treatment, ambulance cover and limited pharmaceuticals (medicines).
                            <br/><br/>
                            <strong><GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> How can I get my OSHC?</strong>
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> You can get your Overseas Student Health Cover (OSHC) from <a href='https://api.allianz.com/myquote/1?groupId=56437'>here</a>
                            <br/><br/>
                            or
                            <br/><br/>
                            <strong><GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> To get Overseas Student Health Cover (OSHC) and/or Overseas Visitor Health Cover (OVHC), you can also </strong>

                            <br/><br/>
                            <Button onClick={openContactModal} className="blinking-btn" size='sm' style={{backgroundColor: "#064CAC", boxShadow: '1px 1px 1px #EC373C', color: '#EC373C', border: 'none'}}><a style={{textDecoration: 'none', color: 'whitesmoke'}}><strong>Request a Call Back</strong></a></Button>              


                           
                            </p>
          </Col>
          <Col sm={6}>           
            <h5 className='text-secondary'><a className='anchor-tag' href='https://payment.ovhcallianzassistance.com.au/en/?agencyID=856437&groupCodeID=A600'>Allianz Care Overseas Visitor Health Cover</a></h5>
            <a href='https://payment.ovhcallianzassistance.com.au/en/?agencyID=856437&groupCodeID=A600'>            
            <div className='home-page-div-two' ></div>
            </a>
            <br/>
            <strong style={{color: 'black', fontSize: '1em'}}>What is Overseas Visitor Health Cover (OVHC)?</strong>
            <p style={{textAlign: 'left'}}>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> Overseas visitors’ health cover (OVHC) is a health insurance which is designed specifically for the people coming to live and work in Australia on a visa. If this visa has a 8501 visa condition, then it’s mandatory to get the Overseas Visitors Health Cover (OVHC) from a provider who meets 8501 visa condition such as Allianz Care Australia, BUPA, Medibank, NIB, AHM etc.
                            <br/><br/>
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> <strong>Overseas visitors’ health cover (OVHC) can cover the following:</strong>
                            <br/><br/>
                            <ul>
                              <li>Hospitalisation</li>
                              <li>Emergency Ambulance</li>
                              <li>Doctor’s Appointments</li>
                              <li>Medical Tests</li>
                              <li>Prescribed Medicines</li>
                              <li>Repatriation</li>
                            </ul>
                            
                            <GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> Allianz Care Overseas Visitor Health Cover (OVHC) has many cover options which can be checked and compared with other providers such as BUPA, Medibank, and NIB.
                            <br/><br/>
                            <strong><GoTriangleRight className='go-paper-airplane right-arrow-symbol'/> To get Overseas Student Health Cover (OSHC) and/or Overseas Visitor Health Cover (OVHC), you can also </strong>

                            <br/><br/>
                            <Button onClick={openContactModal} className="blinking-btn" size='sm' style={{backgroundColor: "#064CAC", boxShadow: '1px 1px 1px #EC373C', color: '#EC373C', border: 'none'}}><a style={{textDecoration: 'none', color: 'whitesmoke'}}><strong>Request a Call Back</strong></a></Button>              

                            </p>

          </Col>
        </Row>
    </Container>
    {/* <div className='home-page-div-one' >
      <Container>
        <Row>
          <Col sm={6}>
            <h6 style={{textAlign: 'left'}}>
                    <span style={{backgroundColor: 'rgb(0, 0, 0, 0.2)'}}>
                      <span onClick={openContactModal} style={{color: 'white'}} className='request-a-call-back'>Request a Call Back</span>
                      
                    </span>
            </h6>
          </Col>
          <Col sm={6}>
          <h6 style={{textAlign: 'right'}}>
                    <span style={{backgroundColor: 'rgb(0, 0, 0, 0.2)'}}>
                      <span style={{color: 'white'}} className='request-a-call-back'><BiSolidPhoneCall style={{fontSize: '1em', zIndex: '1'}} /> <a href="tel:+61479070270" style={{textDecoration: 'none', color: 'white'}}>+61479070270</a></span>
                      
                    </span>
            </h6>
          </Col>
        </Row>        
      </Container>                  
                  <br/>
                  <h3 style={{textAlign: 'left'}}>
                    <span style={{backgroundColor: 'rgb(0, 0, 0, 0.2)'}}>
                      <span style={{color: 'white'}}>Policy</span> <span style={{color: 'red'}}>Direct</span>
                      </span>
                  </h3>
                  <br/>
                  <h4 style={{textAlign: 'left'}}><span style={{color: 'white', backgroundColor: 'rgb(0, 0, 0, 0.2)'}}>The best International Healthcare Provider</span></h4>

    </div> */}

        <br/>
        <ClientsFeedback/>
        <br/>
        <Card className="text-center">
            {/* <Card.Header className='p-5' style={{marginTop: '0', marginBottom: '0', paddingTop: '0', paddingBottom: '0'}}>
                <h4 style={{marginTop: '0', marginBottom: '0', paddingTop: '0', paddingBottom: '0'}}>Policy Direct Australia</h4>
            </Card.Header> */}
            <Card.Body className='p-5'>                
                <Card.Text>               
                                      
                      <h5 style={{color: 'black'}}>Policy Direct</h5>
                <p style={{color: 'black', textAlign: 'left', fontSize: '.9em'}}>
                Policy Direct is a professional and trustworthy organisation which can help you in getting best Overseas Student Health Cover (OSHC) and Overseas Visitor Health Cover (OVHC) which is mandatory for getting visa.

                </p>
                <h5 style={{color: 'black'}}>Why Choose Us?</h5>
                <p style={{color: 'black', textAlign: 'left', fontSize: '.9em'}}>
                Policy Direct is a product of Aussie New Zea Migration and Education Consultancy a renowned Education and Migration Consultancy based in Melbourne. 
                At Policy Direct, We offer a wide range of Overseas Student Health Cover (OSHC) and Overseas Visitor Health Cover (OVHC) options from our associated providers such as Allianz Care Australia, BUPA, Medibank, NIB, & AHM.
                
                
                </p>
                <h5 style={{color: 'black'}}>Our Providers are:</h5><br/>
                <Container>
                  <Row>
                    <Col style={{marginRight: '1em', marginBottom: '1em'}}>
                    <a /* style={{marginRight: '1em', marginBottom: '1em'}} */ href='https://payment.ovhcallianzassistance.com.au/en/?agencyID=856437&groupCodeID=A600'>          
                    <img
                          src={allianzCareLogo}
                          width="180px"
                          height="80px"
                          style={{borderRadius: "10px", zIndex: '1', /* boxShadow: '2px 2px 2px' */}}
                      />
                    </a>
                    </Col>
                    <Col style={{marginRight: '1em', marginBottom: '1em'}}>
                    <a href='https://bupa.com.au/migration-agents/iodnqjq5yeesnvze4iyaug'>          
                    <img
                          src={bupaLogo}
                          width="180px"
                          height="80px"
                          style={{borderRadius: "10px", zIndex: '1', /* boxShadow: '2px 2px 2px' */}}
                      />
                    </a> 
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{marginRight: '1em', marginBottom: '1em'}}>
                    <a href='https://medibank.com.au/bin/medibank/affiliates/?affiliateID=5000003650&affiliateName=AussieNewZeaMigration&EducationConsultancy&productType=visitors&productID=everyday-hospital-medical'>          
                    <img
                          src={medibankLogo}
                          width="180px"
                          height="80px"
                          style={{borderRadius: "10px", zIndex: '1', /* boxShadow: '2px 2px 2px' */}}
                      />
                    </a> 
                    </Col>
                    <Col style={{marginRight: '1em', marginBottom: '1em'}}>
                    <a href='http://www.nib.com.au/overseas-working-visitors/new/join/custom?source=44797&type=W'>          
                    <img
                          src={nibLogo}
                          width="180px"
                          height="80px"
                          style={{borderRadius: "10px", zIndex: '1', /* boxShadow: '2px 2px 2px' */}}
                      />
                    </a> 
                    </Col>
                    
                  </Row>
                  <Row>
                  <Col style={{marginRight: '1em', marginBottom: '1em'}}>
                              
                    <img
                          className='ahm-logo'
                          onClick={openContactModal}
                          src={ahmLogo}
                          width="180px"
                          height="80px"
                          style={{borderRadius: "10px", zIndex: '1', /* boxShadow: '2px 2px 2px' */}}
                      />
                     
                    </Col>
                    
                  </Row>
                  
                </Container>       
                  
                {/* <Button style={{backgroundColor: "#8D021F", border: 'none'}}><a href='/contactus' style={{textDecoration: 'none', color: 'white'}}>Get Quote</a></Button>
 */}
                {/* <HomeCarousel/> */}
                 
            </Card.Text>
            </Card.Body>
            <Card.Footer className="text-muted p-5 mb-0">
            <h5 style={{color: 'black'}}>Code of Conduct:</h5>
           {/*  <div>
              Click on the link below
            </div> */}
            <a target='_blank' href='https://www.mara.gov.au/tools-for-agents-subsite/Files/code-of-conduct-march-2022.pdf'>https://www.mara.gov.au/tools-for-agents-subsite/Files/code-of-conduct-march-2022.pdf</a>
            <br/><br/>
            <h5 style={{color: 'black'}}>Consumer Guide:</h5>
            <a target='_blank' href='https://www.mara.gov.au/get-help-visa-subsite/FIles/consumer_guide_english.pdf'>https://www.mara.gov.au/get-help-visa-subsite/FIles/consumer_guide_english.pdf</a>
            </Card.Footer>

            
    </Card>
    <ContactUsModal
        showModal={showModal} 
        closeModal={closeModal} 
      />

    </main>

    </>
  )
}

export default Home
