import React from 'react'

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import WorkingVisaCoverOptionsTable from '../Tables/WorkingVisaCoverOptionsTable';


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';

const WorkingVisaCoverOptions = () => {
  return (
    <main style={{width: '100%', height: 'auto'}} className="text-secondary">
        
        <Card className="text-center">
            {/* <Card.Header className='p-5'>
                <h2 className='title'>Working Visa Cover Options</h2>
            </Card.Header> */}
            <Card.Body className='p-5'>                
                <Card.Text>

               
                <h6 style={{backgroundColor: 'black', color: 'white', fontWeight: 'bolder', padding: '1em', boxShadow: '1.5px 1.5px 1.5px red', border: 'none', borderRadius: '8px'}}>Working Visa Subclass (188, 407, 408, 417, 
                461, 462, 476, 482, 485, 491, 494) </h6>
                  <WorkingVisaCoverOptionsTable/>
                 
                </Card.Text>
            </Card.Body>
            <Card.Footer className="text-muted p-5 mb-0"></Card.Footer>

            
    </Card>
    </main>
  )
}

export default WorkingVisaCoverOptions
