import React, {useRef, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
// import { contactUs } from '../../actions/contactmessages.js';
import emailjs from '@emailjs/browser';
import './ContactForm.css';
import './BlinkingButton.css';

const SubscribeForm = ({showError, setShowError, errorHandler, setErrorHandler}) => {

  const [message, setMessage] = useState(null); 

    const [subscribeData, setSubscribeData] = useState({
      //firstName: '',
      //lastName: '',
      //fullName: '',
      //mobile: '',
      email: '',
      //message: ''
    });
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmitSubscribe = async (e) => {
      e.preventDefault();
        //console.log(contactData)
      /* dispatch(contactUs(contactData, setMessage, navigate)) */      

      emailjs.sendForm('service_4zp02vg', 'template_jof1x0e', form.current, '5mCJhaJUWGKCrjosx')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text)
      });

      setSubscribeData({
        /* firstName: '',
        lastName: '', */
        //fullName: '',
        //mobile: '',
        email: '',
        //message: ''
      })  
 
    }

    const form = useRef();
  return (
    <>

    
        {message}
        <Form ref={form} onSubmit={handleSubmitSubscribe} className='contact-form' /* style={{marginLeft: '25%', marginRight: '25%'}} */>
        
        <Form.Group className="mb-3" controlId="formBasicEmail">            
        <Form.Control
            size='sm' 
            type="email" 
            placeholder="Email..." 
            required
            name='email'
            value={subscribeData.email}
            onChange={(e) => setSubscribeData({...subscribeData, email: e.target.value})}
            />            
        </Form.Group>         
      
        <Button className="blinking-btn" size='sm' style={{backgroundColor: 'red', color: 'white', border: 'none'}} type="submit">
          Subscribe
        </Button>
    </Form>
    </>
  )
}

export default SubscribeForm