import React, {useState} from 'react'

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import HomeCarousel from './HomeCarousel';
import AccordionHome from '../Accordions/AccordionHome';
import FixedSideBar from '../FixedSideBar';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Accordion from 'react-bootstrap/Accordion';
import logo from '../../images/policydirectlogo.png'
import './TouristVisa.css';

import './Home.css';
import ContactUsModal from '../Modals/ContactUsModal';
const SponsoredParentVisa = () => {
    //modal js
 
  const [showModal, setShowModal] = useState(false);

  const openContactModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  //
  return (
    <>
    {/* <FixedSideBar/> */}
    
    <main style={{width: '100%', height: 'auto'}} className="text-secondary">
        
        <Card className="text-center">
            {/* <Card.Header className='p-5' style={{marginTop: '0', marginBottom: '0', paddingTop: '0', paddingBottom: '0'}}>
                <h4 style={{marginTop: '0', marginBottom: '0', paddingTop: '0', paddingBottom: '0'}}>Policy Direct Australia</h4>
            </Card.Header> */}
            <Card.Body className='p-5'>                
                <Card.Text>               
                      <h6 style={{color: 'black'}}>Sponsored Parent Visa (subclass 870)</h6>
                <p style={{color: 'black', textAlign: 'left'}}>
                <strong>Sponsored Parent Visa (subclass 870): </strong><br/>
                The Australian Sponsored Parent Visa (Subclass 870) is a temporary visa that is issued to the parents of Australian citizens and permanent residents, allowing them to live in Australia with their children for a maximum of ten years.
                <br/><br/>
                <strong>To be able to sponsor your parent for an 870 visa, you must fulfill the following conditions:</strong>
                <br/>
                <ul>
                    <li>You must be an Australian citizen or permanent resident, who has been resident in Australia for at least four years.</li>
                    <li>You must be the legal child of your parents (biological, adoptive, or step-child). You may also sponsor your spouse or partner’s parents</li>
                    <li>You must not have been unlawfully living in Australia during the last four years.</li>
                    <li>You must not have held a bridging visa other than the bridging visas A, B or C during the last four years.</li>
                    <li>You (and your spouse/partner) must have a joint income of at least AUD 83,454. If you do not have a spouse/partner, you must meet the income requirements yourself.</li>
                    <li>You must not have any debts to the Australian government.</li>
                </ul>
                
                <strong>Who Can I Sponsor to Australia for the 870 Visa?</strong><br/>
                You can sponsor your parents or your parents’-in-law (your spouse’s or partner’s parents), but not both parties simultaneously. So, one household can sponsor only two parents at a time, as such:
                <br/>
                <ul>
                    <li>One or both of your parents, or;</li>
                    <li>One or both of your parents-in-law, or;</li>
                    <li>One of your parents and one of your parents-in-law.</li>
                </ul>
                
                <strong>
                You can join Facebook conversation on Parent visas (subclass 870, 804, 143, 864 etc.) at : <a href='https://www.facebook.com/groups/174435117916858'>https://www.facebook.com/groups/174435117916858</a>
                </strong>                

                </p>
                <h6 style={{color: 'black'}}>Why Aussie New Zea Migration?</h6>
                <p style={{color: 'black', textAlign: 'left'}}>
                Aussie New Zea Migration can guide you in the right direction. We have an extensive experience of filling up applications with appropriate documents without any error. This saves a lot of your time and energy. You can contact us or {/* <a href='/contactus'>Request a call back</a>. */}
                <br/><br/>
                <Button onClick={openContactModal} className="blinking-btn" size='sm' style={{backgroundColor: "#064CAC", boxShadow: '1px 1px 1px #EC373C', color: '#EC373C', border: 'none'}}><a style={{textDecoration: 'none', color: 'whitesmoke'}}><strong>Request a Call Back</strong></a></Button>              

                <br/><br/>
                <strong>Disclaimer: </strong> ‘Aussie New Zea Migration & Education Consultancy’ and its associates are independent consulting entities which are not associated in anyway with the Australian ‘Department of Home Affairs’ (DOHA). Information on this website does not constitute personal migration advice. For a customized migration advice based on your personal circumstances, please <a href="tel:+61479070270">call</a> and talk to one of our Immigration Consultants or register your interest with our Associates.

                </p>
                
               
            </Card.Text>
            </Card.Body>
            <Card.Footer className="text-muted p-5 mb-0"></Card.Footer>

            
    </Card>
    <ContactUsModal
        showModal={showModal} 
        closeModal={closeModal} 
    />
    </main>

    </>
  )
}

export default SponsoredParentVisa
