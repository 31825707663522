import { combineReducers } from "redux";

import auth from "./auth";
import allUsers from './allUsers'
import contactmessages from './contactMessages'
//import quotes from './quotes';
//import uploads from './uploads'
export default combineReducers({
    auth,
    allUsers,
    contactmessages,
    //quotes
});
