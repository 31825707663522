import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ContactForm from '../Forms/ContactForm';
import './BlinkingButton.css';

function ContactUsModal({ showModal, closeModal }) {
  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title style={{fontSize: '1em', textDecoration: 'underline red'}}><strong>Request a Call Back</strong></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ContactForm/>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal} size='sm' className='bg-dark'>
          Close
        </Button>
        {/* You can add additional buttons here if needed */}
      </Modal.Footer>
    </Modal>
  );
}

export default ContactUsModal;
