import {START_LOADING, END_LOADING, GET_ALL_USERS, GET_SINGLE_USER } from '../constants/actionTypes';
const allUsersReducer = ( state = {isLoading: true, allUsers: []},/*state=[]posts=[]*/ action) => {
   switch(action.type) {
    case START_LOADING:
        return {
            ...state,
            isLoading: true
        };
    case END_LOADING:
        return {
            ...state,
            isLoading: false
        };
       
        case GET_ALL_USERS:
            return {
                ...state,
                //postCards: action.payload.data,
                allUsers: action.payload.data,
                };
        
        case GET_SINGLE_USER:
            return {
                ...state,
                //postCards: action.payload.data,
                singleUser: action.payload.data,
                };
        /* case FETCH_BY_SEARCH: 
            return {
                ...state,
                posts: action.payload    
        };
        case FETCH_POST: 
            return {
                ...state,
                post: action.payload    
        };
        case CREATE:
        return {...state, posts: [...state.posts, action.payload] };    
        case UPDATE:
        return {...state, posts: state.posts.map((post) => post._id === action.payload._id ? action.payload : post)};    
        case DELETE:
            return {...state, posts: state.posts.filter((post) => post._id !== action.payload)};
        case LIKE:
            return {...state, posts: state.posts.map((post) => post._id === action.payload._id ? action.payload : post)};
        case COMMENT:
            return {
                ...state,
                posts: state.posts.map((post) => {
                    // return all the other posts normally...                    
                    if(post._id === action.payload._id) {
                        return action.payload;
                    }
                    //change the only post that just received a comment ...
                    return post;
                })
            };
        case 'DISLIKE':
            return {...state, posts: state.posts.map((post) => post._id === action.payload._id ? action.payload : post)};  

        //return action.payload;
         case SINGLE_VIDEO_UPLOAD:
                    
            return { ...state,
                posts: action.payload.data
            };  
        case GET_SINGLE_VIDEOS:
                    
            return { ...state,
                posts: action.payload.data
            };  
      */
 
        default:
        return state;
        //break;
   }
}
export default allUsersReducer;