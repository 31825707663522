import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
//import ContactForm from '../Forms/ContactForm';
import './BlinkingButton.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function ModalOvhc ({ showModalOvhc, closeModalOvhc }) {
  return (
    <Modal show={showModalOvhc} onHide={closeModalOvhc}>
      <Modal.Header closeButton>
        <Modal.Title style={{fontSize: '1em', textDecoration: 'underline red'}}><strong>Overseas Visitor Health Cover</strong></Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Container>
        <Row>
        <Col sm={4}>
          <Button variant='light' style={{color: 'white', backgroundColor: 'black',boxShadow: '1.5px 1.5px 1.5px red', zIndex: '1', border: 'none', marginBottom: '.5em', fontSize: '.8em'}}>
            <a className="tab-item" href='/visitorvisacoveroptions' style={{color: 'white', marginBottom: '.5em', fontSize: '1em'}}>
                <strong>Visitor Visa Subclass<br/>
                 (600, 601, 651, 300)
                 </strong>                
            </a>
          </Button>        
          </Col>
          <Col sm={4}>
          <Button variant='light' style={{color: 'white', backgroundColor: 'black',boxShadow: '1.5px 1.5px 1.5px red', zIndex: '1', border: 'none', marginBottom: '.5em', fontSize: '.8em'}}>
            <a className="tab-item" href='/workingvisacoveroptions' style={{color: 'white', marginBottom: '.5em', fontSize: '1em'}}>
                <strong>Working Visa Subclass<br/>
                (188, 407, 408, 417, 
                461, 462, 476, 482, 485, 491, 494)
                 </strong>                
            </a>
          </Button>        
          </Col>
          <Col sm={4}>
          <Button variant='light' style={{color: 'white', backgroundColor: 'black',boxShadow: '1.5px 1.5px 1.5px red', zIndex: '1', border: 'none', marginBottom: '.5em', fontSize: '.8em'}}>
            <a className="tab-item" href='/nonworkingvisacoveroptions' style={{color: 'white', marginBottom: '.5em', fontSize: '1em'}}>
                <strong>Non Working Visa Subclass<br/>
                (BVA, BVB, BVC, 590, 870)
                </strong>                
            </a>
          </Button>        
          </Col>
        </Row>
      </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModalOvhc} size='sm' className='bg-dark'>
          Close
        </Button>
        {/* You can add additional buttons here if needed */}
      </Modal.Footer>
    </Modal>
  );
}

export default ModalOvhc;
