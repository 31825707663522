import React, {useState} from 'react'

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import HomeCarousel from './HomeCarousel';
import AccordionHome from '../Accordions/AccordionHome';
import FixedSideBar from '../FixedSideBar';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Accordion from 'react-bootstrap/Accordion';
import logo from '../../images/policydirectlogo.png'

import './TouristVisa.css';
import './Home.css';
import ContactUsModal from '../Modals/ContactUsModal';
const SkilledVisas = () => {

    //modal js
 
  const [showModal, setShowModal] = useState(false);

  const openContactModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  //
  return (
    <>
    {/* <FixedSideBar/> */}
    
    <main style={{width: '100%', height: 'auto'}} className="text-secondary">
        
        <Card className="text-center">
            {/* <Card.Header className='p-5' style={{marginTop: '0', marginBottom: '0', paddingTop: '0', paddingBottom: '0'}}>
                <h4 style={{marginTop: '0', marginBottom: '0', paddingTop: '0', paddingBottom: '0'}}>Policy Direct Australia</h4>
            </Card.Header> */}
            <Card.Body className='p-5'>                
                <Card.Text>

               
                      <h6 style={{color: 'black'}}>Skilled Visas (subclass 189, 190 and 491)</h6>
                <p style={{color: 'black', textAlign: 'left'}}>
                <strong>Skilled Visa (subclass 189) </strong><br/>
                The Skilled Independent (Subclass 189) is a point-based visa designed to select a certain set of skilled people required in different states of Australia.
                <br/>
                It allows skilled workers to move, live and work in Australia without the need of having a sponsorship of a family member or nomination by the state or territory government.
                <br/><br/>
                <strong>To be eligible for this visa, you must have:</strong><br/>
                <ul>
                    <li>Below 45 years of age.</li>
                    <li>Nominate an occupation from Medium and Long-term Strategic Skills List (MLTSSL)</li>
                    <li>At least Competent English means 6 each in IELTS or 50 each in PTE Academic</li>
                    <li>Have your skills assessed by the relevant assessing authority</li>
                    <li>Achieve the score specified in your visa application</li>
                    <li>Meet the health and character requirements</li>
                </ul>
                <br/>
                You are required to submit an Expression of Interest (EOI) and score at least 65 points to get an invitation from the Department of Home Affairs, Australia for applying the visa.
                <br/>
                <strong>The points in EOI are awarded on different factors such as</strong><br/>
                <ul>
                    <li>Age</li>
                    <li>Education Qualification</li>
                    <li>English Score</li>
                    <li>Work Experience</li>
                    <li>Single (No Partner)</li>
                    <li>Partner Skills and English Score</li>
                    <li>NAATI Accreditation</li>
                    <li>Professional Year</li>                    
                </ul>
                <br/>
                <strong>Skilled Visa (subclass 190)</strong><br/>
                The Skilled Nominated (subclass 190) is a permanent resident visa that allows overseas skilled workers, to live and work in Australia <strong>who are nominated by the state or government agency</strong> and have at least 65 points test.
                <br/>
                <stron>To be eligible for this visa, you must have:</stron><br/>
                <ul>
                    <li>Below 45 years of age.</li>
                    <li>Nominate an occupation from Medium and Long-term Strategic Skills List (MLTSSL) </li>
                    <li>At least Competent English means 6 each in IELTS or 50 each in PTE Academic</li>
                    <li>Have your skills assessed by the relevant assessing authority</li>
                    <li>Achieve the score specified in your visa application</li>
                    <li>Meet the health and character requirements</li>
                </ul>
                
                You are required to submit an Expression of Interest (EOI) and score at least 65 points to get an invitation from the Department of Home Affairs, Australia for applying the visa.
                <br/><br/>
                <strong>The points in EOI are awarded on different factors such as</strong><br/>
                <ul>
                    <li>Age</li>
                    <li>Education Qualification</li>
                    <li>English Score</li>
                    <li>Work Experience</li>
                    <li>Single (No Partner)</li>
                    <li>Partner Skills and English Score</li>
                    <li>NAATI Accreditation</li>
                    <li>Professional Year</li>
                    <li>5 points for State Nomination</li>
                </ul>
                
                
                <strong>Skilled Work Regional (provisional) subclass 491 Visa</strong><br/>
                
                Subclass 491 is a Provisional/temporary Visa. It is also a points tested visa like subclass 189 and subclass 190 visa. It enables eligible professionals and their family members to live, study and work in the designated regional areas of Australia for 5 years. The validity of the visa is 5 years. However, visa holders may be eligible to apply for a Permanent Residence visa which is Subclass 191 after 3 years, provided they fulfil all the requirements of the Department of Home Affairs.
                <br/>
                <br/>
                <strong>
                Eligibility Criteria
                <br/><br/>
                To be eligible for the visa, you must be
                </strong><br/>
                Nominated by a state/territory government or be sponsored by an eligible relative in a designated regional area.
                <br/>
                <strong>Designated Regional Areas Of Australia</strong><br/>
                Designated regional areas are Perth, Adelaide, Canberra, Gold Coast and Hobart. Sydney, Brisbane, and Melbourne do not fall in the list.
                <br/>
                For this visa, points are calculated according to visa applicant’s age, qualification, overseas and Australian work experience, English score, Australian study, their marital status etc.
                <br/>


                <strong>
                You can join Facebook conversation on Skilled visas (subclass 189, 190 ) at : <a href='https://www.facebook.com/groups/286886902979477'>https://www.facebook.com/groups/286886902979477</a>
                </strong>

 	            <br/>


                </p>
                <h6 style={{color: 'black'}}>Why Aussie New Zea Migration?</h6>
                <p style={{color: 'black', textAlign: 'left'}}>
                Aussie New Zea Migration can guide you in the right direction. We have an extensive experience of filling up applications with appropriate documents without any error. This saves a lot of your time and energy. You can contact us or {/* <a href='/contactus'>Request a call back</a>. */}
                <br/><br/>
                <Button onClick={openContactModal} className="blinking-btn" size='sm' style={{backgroundColor: "#064CAC", boxShadow: '1px 1px 1px #EC373C', color: '#EC373C', border: 'none'}}><a style={{textDecoration: 'none', color: 'whitesmoke'}}><strong>Request a Call Back</strong></a></Button>              

                <br/><br/>
                <strong>Disclaimer: </strong> ‘Aussie New Zea Migration & Education Consultancy’ and its associates are independent consulting entities which are not associated in anyway with the Australian ‘Department of Home Affairs’ (DOHA). Information on this website does not constitute personal migration advice. For a customized migration advice based on your personal circumstances, please <a href="tel:+61479070270">call</a> and talk to one of our Immigration Consultants or register your interest with our Associates.

                </p>
                
               
                 
            </Card.Text>
            </Card.Body>
            <Card.Footer className="text-muted p-5 mb-0"></Card.Footer>

            
    </Card>
    <ContactUsModal
        showModal={showModal} 
        closeModal={closeModal} 
      />
    </main>

    </>
  )
}

export default SkilledVisas
