import React from 'react'

//import logo from '../../images/policydirectlogo4.jpeg'
//import logo from '../../images/policydirectlogo11.png'
import logo from '../../images/175percent.png'
import addressmap from '../../images/addressmap.jpg'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

import { BsWhatsapp } from "react-icons/bs"
import { BsFillEnvelopeAtFill } from "react-icons/bs";
import { FaFacebook } from "react-icons/fa";
import ContactForm from '../Forms/ContactForm';
import { BiSolidPhoneCall } from "react-icons/bi";
import { FaWhatsappSquare } from "react-icons/fa";
import WhatsAppButtonGreenSmall from '../../images/WhatsAppButtonGreenSmall.png'
import WhatsAppButtonGreenLarge from '../../images/WhatsAppButtonGreenLarge.png'

import './Footer.css'
import SubscribeForm from '../Forms/SubscribeForm';

const Footer = () => {
  return (
    <>
    
    
    <footer style={{backgroundColor: 'black' }}>
        <Container className='text-dark' style={{backgroundColor: 'black', color: 'white'}} fluid>      
            <Row className='p-5'>
                <Col lg={2} md={4} sm={6} xs={12} style={{marginBottom: '1em'}}>
                <a href='/'>          
                 <img
                      src={logo}
                      width="130px"
                      height="50px"
                      style={{borderRadius: "10px", zIndex: '1', /* boxShadow: '1px 1px 1px red' */}}
                  />
                  </a>  
                <br/><br/>
                <p style={{textAlign: 'left', textJustify: 'inter-word', backgroundColor: 'black', color: 'white', fontSize: '.8em', fontStyle: 'bold'}}>
                Policy Direct is a professional and trustworthy organisation, which can help you in getting best Overseas Student Health Cover (OSHC) and Overseas Visitor Health Cover (OVHC) which is mandatory for getting visa.           

                </p>                
                </Col>
                <Col lg={2} md={4} sm={6} xs={12} style={{marginBottom: '1em'}}>
                  <h6 style={{color: 'white', fontSize: '1em', marginBottom: '1em'}}>Why Choose Us?</h6>
                  
                  <p style={{textAlign: 'left', textJustify: 'inter-word', backgroundColor: 'black', color: 'white', fontSize: '.8em', fontStyle: 'bold', marginTop: '1em'}}>

                  Policy Direct is a product of Aussie New Zea Migration and Education Consultancy a renowned Education and Migration Consultancy based in Melbourne. 
                  At Policy Direct, We offer a wide range of Overseas Student Health Cover (OSHC) and Overseas Visitor Health Cover (OVHC) options from our associated providers such as Allianz Care Australia, BUPA, Medibank, NIB, & AHM.

                  </p>
                </Col>
                <Col lg={2} md={4} sm={6} xs={12} style={{marginBottom: '1em'}}>
                <h6 style={{color: 'white', fontSize: '1em'}}>Request a Call Back</h6>
                <br/>
                <ContactForm />
                </Col>  
                
                
                <Col lg={2} md={4} sm={6} xs={12} style={{marginBottom: '1em'}}>
                <h6 style={{color: 'white', fontSize: '1em'}}>Locate us</h6>
                
                <address style={{color: 'white', fontSize: '.8em', /* textAlign: 'left' */}}>
                      118, Lyndarum Drive,<br/>
                      Epping, Vic 3076, <br/>
                      Australia
                </address>
                <br/>
                {/* <a href='https://www.google.com/maps/place/118+Lyndarum+Dr,+Epping+VIC+3076/@-37.6294713,145.0132851,17z/data=!3m1!4b1!4m6!3m5!1s0x6ad64e84a96def85:0xb89d53c3e0a8c5be!8m2!3d-37.6294756!4d145.01586!16s%2Fg%2F11crstt46g?entry=ttu'>                
 */}                
                <a href='https://www.google.com.au/maps/place/Overseas+Students+%26+Visitors+Health+Cover+Australia,+OSHC+%26+OVHC,+Allianz+Care+Australia,+BUPA,+Medibank,+Travel+Insurance/@-37.6294756,145.01586,15z/data=!4m6!3m5!1s0x6ad64f5f42ae504f:0xf2f237b45d9332d7!8m2!3d-37.6294756!4d145.01586!16s%2Fg%2F11vcl72797?entry=ttu'> 
                  <img
                      src={addressmap}
                      width="140px"
                      height="150px"
                      style={{borderRadius: "10px", zIndex: '1'}}
                  />
                </a>
                </Col>
                <Col lg={2} md={4} sm={6} xs={12} style={{marginBottom: '1em'}}>
                <h6 style={{color: 'white', fontSize: '1em'}}>Contact Us</h6>
                <ListGroup>
                <ListGroup.Item action href="/" style={{backgroundColor: 'black', color: 'white', border: 'none'}} /* className='bg-dark text-white border-white' */>
                    
                    {/* <BsFillEnvelopeAtFill style={{fontSize: '.6em'}}/>  */}<a href='mailto:policydirectau@gmail.com' style={{color: 'white', fontSize: '.8em'}}> policydirectau@gmail.com</a>

                    </ListGroup.Item>
                    <ListGroup.Item action href="/" style={{backgroundColor: 'black', color: 'white', border: 'none'}} /* className='bg-dark text-white border-white' */>
                    <BiSolidPhoneCall style={{fontSize: '.8em'}} /> <a href="tel:+61479070270" style={{color: 'white', fontSize: '.8em'}}>+61479070270</a>
                    <br/><br/>
                    <a href='https://wa.me/61479070270' className='chat-on-whatsapp-button blinking-btn'>          
                      <img
                      src={WhatsAppButtonGreenLarge}
                      width="130px"
                      height="40px"
                      style={{zIndex: '1'}}                      
                      />
                    </a>  
                    {/* <FaWhatsappSquare style={{fontSize: '.8em', color: '#25D366', backgroundColor: 'white'}}/> <a href="tel:+61479070270" style={{color: 'white', fontSize: '.8em'}}>+61479070270</a>
 */}
                    </ListGroup.Item>       

                                 
                    
                  </ListGroup>
                </Col>
                <Col lg={2} md={4} sm={6} xs={12} style={{marginBottom: '1em'}}>
                <h6 style={{color: 'white', fontSize: '1em'}}>Join Facebook Conversation</h6>
                <a href='https://www.facebook.com/groups/1129761294514318/' style={{color: '#3B5998'}}><FaFacebook style={{fontSize: '1.6em'}}/></a>
                <br/><br/>
                <SubscribeForm/>

                </Col>                 
            </Row>       
        </Container>
    </footer>
    
    {/* <footer style={{backgroundColor: 'black' }}>
        <Container className='text-dark' style={{backgroundColor: 'black'}}>      
            <Row className='p-5'>
                <Col sm={6} style={{marginBottom: '1em'}}>
                <a href='/'>          
                 <img
                      src={logo}
                      width="150px"
                      height="50px"
                      style={{borderRadius: "10px", zIndex: '1', boxShadow: '1px 1px 1px red'}}
                  />
                  </a>  
                <br/><br/>
                <p style={{textAlign: 'left', backgroundColor: 'black', color: 'white', fontStyle: 'bold'}}>
                Policy Direct is a professional and trustworthy organisation, which can help you in getting best Overseas Student Health Cover (OSHC) and Overseas Visitor Health Cover (OVHC) which is mandatory for getting visa.
                <br/>
                <strong>Why Choose Us?</strong>
                <br/>
                Policy Direct is a product of Aussie New Zea Migration and Education Consultancy a renowned Education and Migration Consultancy based in Melbourne. 
                At Policy Direct, We offer a wide range of Overseas Student Health Cover (OSHC) and Overseas Visitor Health Cover (OVHC) options from our associated providers such as Allianz Care Australia, BUPA, Medibank, NIB, & AHM.


                </p>                
                </Col>
                <Col sm={2} style={{marginBottom: '1em'}}>
                <h5 style={{color: 'white'}}>Social Media</h5>
                <a href='https://www.facebook.com/groups/1129761294514318/' style={{color: '#3B5998'}}><FaFacebook style={{fontSize: '1.4em'}}/></a>
                </Col>
                <Col sm={4} style={{marginBottom: '1em'}}>
                <h5 style={{color: 'white'}}>Contact</h5>
                <ListGroup>
                <ListGroup.Item action href="/" style={{backgroundColor: 'black', color: 'white', border: 'none'}} >
                    <BsFillEnvelopeAtFill style={{fontSize: '1.3em'}}/> <a href='mailto:info@policydirect.com.au' style={{color: 'white'}}> info@policydirect.com.au</a>
                    <br/>
                    <BsFillEnvelopeAtFill style={{fontSize: '1.3em'}}/> <a href='mailto:policydirectau@gmail.com' style={{color: 'white'}}> policydirectau@gmail.com</a>

                    </ListGroup.Item>
                    <ListGroup.Item action href="/" style={{backgroundColor: 'black', color: 'white', border: 'none'}} >
                    <BiSolidPhoneCall style={{fontSize: '1.4em'}} /> <a href="tel:+61479070270" style={{color: 'white'}}>+61479070270</a>
                    <br/>
                    <FaWhatsappSquare style={{fontSize: '1.4em', color: '#25D366', backgroundColor: 'white'}}/> <a href="tel:+61479070270" style={{color: 'white'}}>+61479070270</a>

                    </ListGroup.Item>                    

                    <ListGroup.Item style={{backgroundColor: 'black', color: 'white', border: 'none'}} >
                    <address>
                      118, Lyndarum Drive Epping, <br/>
                      Victoria 3076, <br/>
                      Australia
                    </address>
                    </ListGroup.Item>             
                    
                  </ListGroup>
                </Col>
                      
            </Row>
            <Row>
              <Col sm={12} style={{color: 'white', marginBottom: '2em'}}>
                <h5 >Request a Call Back</h5>
                <ContactForm />
              </Col>
            </Row>
            <Row>
                   
                        
                    
            </Row>
        </Container>
    </footer>
 */}
    </>
  )
}

export default Footer
