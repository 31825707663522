import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import './ClientsFeedback.css'
import iAmStudyingInAustralia from '../../images/iamstudyinginaustralia.jpg'
import iAmVisitingAustralia from '../../images/iamvisitingaustralia.jpg'
import iAmWorkingInAustralia from '../../images/iamworkinginaustralia.jpg'
const ClientsFeedback = () => {
  return (
    <>
    <Card className='card-clients-feedback' style={{background: 'white'}}>
      <Card.Body >      
      <Container> 
      <Row>
        <Col sm={4} style={{marginBottom: '1em'}}>
        <Card className='card-2-clients-feedback'>
        <Card.Body >
                    <img
                          src={iAmStudyingInAustralia}
                          width="100%"
                          height="300px"
                          style={{borderRadius: "10px", zIndex: '1', /* boxShadow: '2px 2px 2px' */}}
                      />
                      <br/><br/>
                <h6>
                I am studying in Australia
                </h6>
                {/* <strong><em>Kuala Lumpur, Malaysia</em></strong> */}
                <p style={{textAlign: 'left'}}>
                  Meet your <a href='/oshc'><strong>500</strong></a> Students Visa requirements with our <a href='/oshc'><strong>Overseas Student Health Cover</strong></a>
                </p>
            </Card.Body>
        </Card>
        </Col>
        <Col sm={4} style={{marginBottom: '1em'}}>
        <Card className='card-2-clients-feedback'>
        <Card.Body >
                    <img
                          src={iAmVisitingAustralia}
                          width="100%"
                          height="300px"
                          style={{borderRadius: "10px", zIndex: '1', /* boxShadow: '2px 2px 2px' */}}
                      />
                      <br/><br/>
                <h6>
                I am visiting Australia
                </h6>
                <br/>
                <p>
                  Visa <a href='/ovhc'><strong>600, 601, 651 and more...</strong></a>
                </p>

                {/* <strong><em>Kuala Lumpur, Malaysia</em></strong> */}
                <p style={{textAlign: 'left'}}>
                  <a href='/ovhc'><strong>Overseas Visitor Health Cover</strong></a> for when you are visiting Australia on holidays, or for your family, if they are visiting from overseas.
                                  </p>
            </Card.Body>
        </Card>
        </Col>
        <Col sm={4} style={{marginBottom: '1em'}}>
        <Card className='card-2-clients-feedback'>
        <Card.Body >
                    <img
                          src={iAmWorkingInAustralia}
                          width="100%"
                          height="300px"
                          style={{borderRadius: "10px", zIndex: '1', /* boxShadow: '2px 2px 2px' */}}
                      />
                      <br/><br/>
                <h6>
                I am working in Australia
                </h6>
                <br/>
                <p>
                  Visas <a href='/ovhc'><strong>482, 485, 417 and more...</strong></a>
                </p>
                {/* <strong><em>Kuala Lumpur, Malaysia</em></strong> */}
                <p style={{textAlign: 'left'}}>
                   <a href='/ovhc'><strong>Overseas Visitor Health Cover</strong></a> for when you are temporarily working in Australia, or you are visiting on working holidays.
                </p>
            </Card.Body>
        </Card>
        </Col>

      </Row>    
      
    </Container>
      </Card.Body>
    </Card>

    <br/><br/>

    <Card className='card-clients-feedback'>
      <Card.Body >
      <h5 className='cardbody-clients-feedback' style={{color: 'white', marginBottom: '1em'}}>What our clients say about us?</h5>
      <h6 className='cardbody-clients-feedback' style={{color: 'white'}}>100% Satisfied Customers</h6>
      <p className='cardbody-clients-feedback' style={{color: 'white'}}>
        Here is what our clients say about our services. 
      </p>
      <Container> 
      <Row>
        <Col sm={4} style={{marginBottom: '1em'}}>
        <Card className='card-2-clients-feedback'>
        <Card.Body >
                <h6>
                Akhila Sridhara
                </h6>
                {/* <strong><em>Kuala Lumpur, Malaysia</em></strong> */}
                <p style={{textAlign: 'left'}}>
                Thanks to Amit ji he helped me with Health insurance for my parents he was always was there to clear my question. Had wonderful experience with Amit ji .I will definitely recommend to everyone.                </p>
            </Card.Body>
        </Card>
        </Col>
        <Col sm={4} style={{marginBottom: '1em'}}>
        <Card className='card-2-clients-feedback'>
            <Card.Body >
                <h6>Mahim Prasenjit</h6>
                
                <p style={{textAlign: 'left'}}>
                Great service with full details. Amit is very helpful. We had wonderful experience. 👍 …
                </p>
            </Card.Body>
        </Card>
        </Col>
        <Col sm={4} style={{marginBottom: '1em'}}>
        <Card className='card-2-clients-feedback'>
            <Card.Body >
                <h6>
                Nisha Company
                </h6>
                {/* <strong><em>Kuala Lumpur, Malaysia</em></strong> */}
                <p style={{textAlign: 'left'}}>
                It has been a great experience and everything went so smoothly with the great advices given by Amit. Thanks, I will recommend.
                </p>
              </Card.Body>
        </Card>
        </Col>
      </Row>    
      <Row>
        <Col sm={4} style={{marginBottom: '1em'}}>
        <Card className='card-2-clients-feedback'>
            <Card.Body >
                <h6>Sarabjeet Singh</h6>
                <strong><em>India</em></strong>
                <p style={{textAlign: 'left'}}>
                Amazing and quick service by amit ji on my parents health cover, he provided the best possible rate available in market, i recommend to use his service. 👍🏻 …
                </p>

                
            </Card.Body>
        </Card>
        </Col>
        <Col sm={4} style={{marginBottom: '1em'}}>
        <Card className='card-2-clients-feedback'>
            <Card.Body >
                <h6>Jaideep Singh Gohil</h6>
                <strong><em>India</em></strong>
                <p style={{textAlign: 'left'}}>
                Great Service and Prompt Communication, Gives right advice on what product is right for you and your family. Highly recommend their services.
                </p>
                            </Card.Body>
        </Card>
        </Col>
        <Col sm={4} style={{marginBottom: '1em'}}>
        <Card className='card-2-clients-feedback'>
            <Card.Body >
                <h6>
                Farah
                </h6>
                <strong><em>Kuala Lumpur, Malaysia</em></strong>
                <p style={{textAlign: 'left'}}>
                    I am a graduate student from Malaysia. I was searching for effordable health cover for the whole duration of my study visa. Then I requested a call back from policy direct. They gave me the insurance of my needs. I love their services. thanks :)
                </p>
            </Card.Body>
        </Card>
        </Col>
      </Row>
    </Container>
      </Card.Body>
    </Card>
    </>
  )
}

export default ClientsFeedback
