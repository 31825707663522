import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import NavItem from 'react-bootstrap/NavItem'
//import logo from '../../images/policydirectlogo4.jpeg'
//import logo from '../../images/policydirectlogo7.jpeg'
//import logo from '../../images/policydirectlogo11.png'
//import logo from '../../images/policydirectlogo11.png'
//import toYou from '../../images/toyoucursive1.png'
//import logo from '../../images/Logo.png';
import logo from '../../images/175percent.png'
import written from '../../images/Written.png';

import { BiSolidPhoneCall } from "react-icons/bi";
import decode from 'jwt-decode'
import Accordion from 'react-bootstrap/Accordion';
import { BsWhatsapp } from "react-icons/bs"
import { BsFillEnvelopeAtFill } from "react-icons/bs";
import { LOGOUT } from '../../constants/actionTypes';
import { useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';
import { FaFacebook } from "react-icons/fa";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './BlinkingButton.css';
import './Tabs.css';
//import './TabContentOnHover.css'
import './TabHoverUnderline.css'
import { Dropdown } from 'react-bootstrap';
import HoverDropdown from './HoverNavDropdown';
import HoverNavDropdown from './HoverNavDropdown';
import './NavBar.css';
import Stack from 'react-bootstrap/Stack';

import { FaWhatsappSquare } from "react-icons/fa";
import ContactUsModal from '../Modals/ContactUsModal';
import WhatsAppButtonGreenSmall from '../../images/WhatsAppButtonGreenSmall.png'
import WhatsAppButtonGreenLarge from '../../images/WhatsAppButtonGreenLarge.png'
import './UpperNavBar.css';


const UpperNavBar = ({user, setUser}) => {
  //const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));

  //console.log(user)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //const location = useLocation();

  const logout = () => {
    dispatch({type: LOGOUT /*'LOGOUT'*/});
    navigate('/');
    setUser(null);
    //setFormData('') because if new user registers, then after autologin if he/she get logout,then his sign up page is filled with his details
    
  }

  
  const handleHoverFaq = () => {
    console.log()

  }

  const [showDropdown, setShowDropdown] = useState(false);

  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };

  //modal js
 
  const [showModal, setShowModal] = useState(false);

  const openContactModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  //
  return (
    <>    
      <Navbar inverse collapseOnSelect expand="lg" style={{backgroundColor: 'white', color: 'black', marginLeft: '1%', marginRight: '1%'}} /* className='bg-secondary' */ /* className="bg-body-tertiary" */>
      {/* <Container style={{width: '100%', border: '1px solid black', margin: '0'}}>
 */}        <Navbar.Brand ml="auto" href="/" className='responsive-css-logo'>
        <a href='/' style={{marginBotton: '0', marginLeft: '0', paddingBottom: '0'}}>          
                 <img
                      className='logo-image'
                      src={logo}
                      width="160px"
                      height="70px"
                      style={{float: 'left', borderRadius: "10px", zIndex: '1'}}
                 />                       
                  
        </a>          
        {/* <Container fluid bg="dark">
          <Row>
            <Col className='column-left'>
            <a href='/' style={{marginBotton: '0', paddingBottom: '0'}}>          
                 <img
                      className='logo-image'
                      src={logo}
                      width="120px"
                      height="110px"
                      style={{float: 'left', borderRadius: "10px", zIndex: '1'}}
                  />                        
                  
            </a> 
            </Col>
            <Col className='column-right'>
            <a href='/' style={{marginBotton: '0', paddingBottom: '0'}}>          
                 <img
                      src={written}
                      width="120px"
                      height="120px"
                      style={{float: 'left', borderRadius: "10px", zIndex: '1'}}
                  />                          
                  
            </a>
            </Col>
          </Row>
        </Container>  */}            
        
        </Navbar.Brand>       
                    
          {/* <Nav >
          <a href='/' style={{marginBotton: '0', paddingBottom: '0'}}>          
                  <img
                      className='slogan-image'
                      src={written}
                      width="140px"
                      height="140px"
                      style={{float: 'left', borderRadius: "10px", zIndex: '1', paddingTop: '1em'}}
                  />                       
                  
          </a> 
          </Nav> */}
          <Nav>
          <Nav.Link href="https://www.facebook.com/groups/1129761294514318/" className='responsive-css-join-facebook' style={{color: '#064CAC', /* textShadow: '.1px .1px .1px red', */ zIndex: '1', /* marginRight: '.8em', */ fontSize: '.8em'}}/* style={{color: 'blue'}} */>
              <strong style={{fontSize: '1.2em', marginTop: '3em'}}>Join Facebook Conversation</strong> <br/> <FaFacebook style={{fontSize: '1.8em', color: '#3B5998', backgroundColor: 'white', zIndex: '1'}}/>
          </Nav.Link>

            <Nav.Link href="https://wa.me/61479070270" className='responsive-css-whatsapp-button' /* style={{fontSize: '.9em'}} */>
                  <img
                      className='blinking-btn'
                      src={WhatsAppButtonGreenLarge}
                      width="130px"
                      height="38px"
                      style={{zIndex: '1'}}                      
                  />
       </Nav.Link>

                             
          </Nav>          
        
       <Navbar.Collapse className="justify-content-end">
       <Nav.Link href="mailto:info@policydirect.com.au" style={{fontSize: '1em', marginRight: '1em' }}>
        {/* <BsFillEnvelopeAtFill style={{fontSize: '.9em', zIndex: '1'}} /> <a href='mailto:info@policydirect.com.au' style={{color: 'black'}}>info@policydirect.com.au</a> <br/>
 */}        <BsFillEnvelopeAtFill style={{fontSize: '1.3em', zIndex: '1', color:'#064CAC'}} /> <a href='mailto:policydirectau@gmail.com' style={{color: '#064CAC'}}>policydirectau@gmail.com</a>
            {/* <br/><br/> */}
       </Nav.Link>
       <Nav.Link >
       <Button onClick={openContactModal} className="blinking-btn" size='sm' style={{backgroundColor: "#064CAC", boxShadow: '1px 1px 1px #EC373C', color: '#EC373C', border: 'none'}}><a style={{textDecoration: 'none', color: 'whitesmoke'}}><strong>Request a Call Back</strong></a></Button>              
        </Nav.Link>
       {/* <Nav.Link href="https://wa.me/61479070270" >
        <img
                      className='blinking-btn'
                      src={WhatsAppButtonGreenSmall}
                      width="130px"
                      height="40px"
                      style={{zIndex: '1'}}
                      
                  />
       </Nav.Link><br/> */}
       {/* <Nav.Link href="tel:+61479070270" style={{fontSize: '.9em'}}><FaWhatsappSquare style={{fontSize: '1.4em', color: '#25D366', backgroundColor: 'white'}}/> +61479070270<br/>
       <BiSolidPhoneCall style={{fontSize: '1.4em', zIndex: '1'}} /> +61479070270
       </Nav.Link><br/> */}       

       </Navbar.Collapse>
       
        {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" className="justify-content-end"/>
 */}      {/* </Container> */}
    </Navbar>
<hr style={{marginTop: '0'}}/>
<ContactUsModal 
        showModal={showModal} 
        closeModal={closeModal} 
      />
      
</>
  )
}

export default UpperNavBar


