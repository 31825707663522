import React from 'react'

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

const Missing = () => {
  return (
    <main style={{width: '100%', height: 'auto'}} className="text-secondary">
        
        <Card className="text-center">
            <Card.Header className='p-5'>
                
            </Card.Header>
            <Card.Body className='p-5'>
                
                <Card.Text>
                  <h2>Missing...</h2>
                </Card.Text>
            </Card.Body>
            <Card.Footer className="text-muted p-5 mb-0"></Card.Footer>

            
    </Card>
    </main>
  )
}

export default Missing
