import React, {useState} from 'react'

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import HomeCarousel from './HomeCarousel';
import AccordionHome from '../Accordions/AccordionHome';
import FixedSideBar from '../FixedSideBar';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Accordion from 'react-bootstrap/Accordion';
import logo from '../../images/policydirectlogo.png'
import './TouristVisa.css';

import './Home.css';
import ContactUsModal from '../Modals/ContactUsModal';
const TouristToStudentVisaConversion = () => {

    //modal js
 
  const [showModal, setShowModal] = useState(false);

  const openContactModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <>
    {/* <FixedSideBar/> */}

    
    <main style={{width: '100%', height: 'auto'}} className="text-secondary">
        
        <Card className="text-center">
            {/* <Card.Header className='p-5' style={{marginTop: '0', marginBottom: '0', paddingTop: '0', paddingBottom: '0'}}>
                <h4 style={{marginTop: '0', marginBottom: '0', paddingTop: '0', paddingBottom: '0'}}>Policy Direct Australia</h4>
            </Card.Header> */}
            <Card.Body className='p-5'>                
                <Card.Text>

                
                      <h6 style={{color: 'black'}}>Tourist to Student Visa Conversion</h6>
                <p style={{color: 'black', textAlign: 'left'}}>
                If you hold Australian Tourist visa and there is no “NO FURTHER STAY” on it and have decided to convert it into a student visa, then you can extend your stay in Australia by getting a student visa – subclass 500. This visa conversion will not affect your student visa application process.
                <br/><br/>
                Visitor visas are mainly three subclass such as:
                <ol>
                    <li>
                        Subclass 651 – eVisitor	
                    </li>
                    <li>
                    Subclass 601 – Electronic Travel Authority Visa
                    </li>
                    <li>
                    Subclass 600 – Visitor Visa
                    </li>
                </ol>
                
                For conversion from Tourist Visa to Student Visa, you shouldn’t hold any bad records. Bad records can range among the following:
                <br/>
                <ul>
                    <li>
                        Unexplainable study gap
                    </li>
                    <li>Low financial stability</li>
                    <li>Case filed against you</li>
                    <li>Previously rejected student visa</li>
                    <li>No IELTS</li>
                    <li>Working during visitation</li>
                    <li>
                        Have an 8503 or “no further stay” condition applied on your Visa
                        If any of the above conditions match with your status, then you can get help from a migration expert consultant to guide you in straightening matters to a great extent.

                    </li>
                
                </ul>
               
                </p>
                <h6 style={{color: 'black'}}>Why Aussie New Zea Migration?</h6>
                <p style={{color: 'black', textAlign: 'left'}}>
                Aussie New Zea Migration can guide you in the right direction. We have an extensive experience of filling up applications with appropriate documents without any error. This saves a lot of your time and energy. You can contact us or {/* <a href='/contactus'>Request a call back</a>. */}
                <br/><br/>
                <Button onClick={openContactModal} className="blinking-btn" size='sm' style={{backgroundColor: "#064CAC", boxShadow: '1px 1px 1px #EC373C', color: '#EC373C', border: 'none'}}><a style={{textDecoration: 'none', color: 'whitesmoke'}}><strong>Request a Call Back</strong></a></Button>              

                <br/><br/>

                <strong>Disclaimer: </strong> ‘Aussie New Zea Migration & Education Consultancy’ and its associates are independent consulting entities which are not associated in anyway with the Australian ‘Department of Home Affairs’ (DOHA). Information on this website does not constitute personal migration advice. For a customized migration advice based on your personal circumstances, please <a href="tel:+61479070270">call</a> and talk to one of our Immigration Consultants or register your interest with our Associates.

                </p>
                
               
                 
            </Card.Text>
            </Card.Body>
            <Card.Footer className="text-muted p-5 mb-0"></Card.Footer>

            
    </Card>
    <ContactUsModal
        showModal={showModal} 
        closeModal={closeModal} 
      />
    </main>

    </>
  )
}

export default TouristToStudentVisaConversion
