import { VERIFY_OTP, REGISTRATION, AUTH, AUTH_EMPLOYEES, AUTH_ADMIN, AUTH_LOGIN, VERIFY, LOGOUT, FORGOT_PASSWORD, VERIFY_EMPLOYEES, AUTH_ADMIN_STAFF, VERIFY_ADMIN_STAFF, UPLOAD_FILE, EMPLOYEE_REGISTRATION_FROM_ADMIN_END, VERIFY_AND_UPDATE_EMPLOYEE_REGISTRATION_FROM_ADMIN_END, UPGRADE_FROM_USER_TO_EMPLOYEE, ADMIN_STAFF_REGISTRATION_FROM_ADMIN_END, FORGOT_USERNAME, RESET_PASSWORD, PROFILE_IMAGE_UPLOAD, COVER_IMAGE_UPLOAD, FETCH_ALL_USERS, SINGLE_VIDEO_UPLOAD, ADD_FRIEND, MATCH_OTP_AND_SIGN_IN, VERIFY_OTP_FORGOT_PASSWORD} from '../constants/actionTypes';

const authReducer = (state={authData: null}, action) => {
    switch(action.type) {
        case VERIFY_OTP:
            //localStorage.setItem('profile', JSON.stringify({ ...action?.data}))
            return {... state, authData: action?.data };

        case VERIFY_OTP_FORGOT_PASSWORD:
            //localStorage.setItem('profile', JSON.stringify({ ...action?.data}))
            return {... state, authData: action?.data };
    
          
        case AUTH:
            //console.log(action?.data)
            //localStorage.setItem('profile', JSON.stringify({ ...action?.data}))
            return { ...state, authData: action?.data };   
       
            case AUTH_LOGIN:
                localStorage.setItem('profile', JSON.stringify({ ...action?.data}))
                return {... state, authData: action?.data };
    
            case LOGOUT:
                localStorage.clear();
                return { ...state, authData: null}
        
        
        case MATCH_OTP_AND_SIGN_IN:
            localStorage.setItem('profile', JSON.stringify({ ...action?.data}))
            return {... state, authData: action?.data };
    
        default:
            return state;
    }
}

export default authReducer;