import React, {useState} from 'react'

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import HomeCarousel from './HomeCarousel';
import AccordionHome from '../Accordions/AccordionHome';
import FixedSideBar from '../FixedSideBar';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Accordion from 'react-bootstrap/Accordion';
import logo from '../../images/policydirectlogo.png'

import './TouristVisa.css';
import './Home.css';
import ContactUsModal from '../Modals/ContactUsModal';
const StudentDependentVisa = () => {

  //modal js
 
  const [showModal, setShowModal] = useState(false);

  const openContactModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  //
  return (
    <>
    {/* <FixedSideBar/> */}
    
    <main style={{width: '100%', height: 'auto'}} className="text-secondary">
        
        <Card className="text-center">
            {/* <Card.Header className='p-5' style={{marginTop: '0', marginBottom: '0', paddingTop: '0', paddingBottom: '0'}}>
                <h4 style={{marginTop: '0', marginBottom: '0', paddingTop: '0', paddingBottom: '0'}}>Policy Direct Australia</h4>
            </Card.Header> */}
            <Card.Body className='p-5'>                
                <Card.Text>

                
                      <h6 style={{color: 'black'}}>Student Dependent Visa(subclass 500)</h6>
                <p style={{color: 'black', textAlign: 'left'}}>
                <strong>Student Dependent Visa (subclass 500): </strong><br/>
                Student dependent visa is for members of the family unit of student visa holder, who were not included in the student's visa application, but were declared as members of the family unit.
                <br/><br/>
                <strong>Can a Student Visa Dependent Work in Australia? </strong><br/>
                Yes, student visa dependents are allowed to work in Australia. The number of hours you are allowed to work depends on whether the primary student visa holder is studying a Masters or higher level degree or not.
                <br/><br/>
                <strong>Can Spouse Work on Dependent Student Visa in Australia?</strong><br/>
                Yes, spouses of student visa holders can work while they are student dependent visa. The number of hours in a week they are allowed to work depends on the student visa holder's level of study. Spouse of a person who is doing Masters by coursework can work full time.
                <br/>
                You can join Facebook conversation on Student dependent visa at : <a href='https://www.facebook.com/groups/293046182302649'>https://www.facebook.com/groups/293046182302649</a>

                </p>
                <h6 style={{color: 'black'}}>Why Aussie New Zea Migration?</h6>
                <p style={{color: 'black', textAlign: 'left'}}>
                Aussie New Zea Migration can guide you in the right direction. We have an extensive experience of filling up applications with appropriate documents without any error. This saves a lot of your time and energy. You can contact us or {/* <a href='/contactus'>Request a call back</a>. */}
                <br/><br/>
                <Button onClick={openContactModal} className="blinking-btn" size='sm' style={{backgroundColor: "#064CAC", boxShadow: '1px 1px 1px #EC373C', color: '#EC373C', border: 'none'}}><a style={{textDecoration: 'none', color: 'whitesmoke'}}><strong>Request a Call Back</strong></a></Button>              

                <br/><br/>
                <strong>Disclaimer: </strong> ‘Aussie New Zea Migration & Education Consultancy’ and its associates are independent consulting entities which are not associated in anyway with the Australian ‘Department of Home Affairs’ (DOHA). Information on this website does not constitute personal migration advice. For a customized migration advice based on your personal circumstances, please <a href="tel:+61479070270">call</a> and talk to one of our Immigration Consultants or register your interest with our Associates.

                </p>
                
                
            </Card.Text>
            </Card.Body>
            <Card.Footer className="text-muted p-5 mb-0"></Card.Footer>

            
    </Card>
    <ContactUsModal
        showModal={showModal} 
        closeModal={closeModal} 
      />
    </main>

    </>
  )
}

export default StudentDependentVisa
