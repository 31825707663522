import React from 'react'

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
//import VisitorVisaCoverOptionsTable from '../Tables/VisitorVisaCoverOptionsTable';
import NonWorkingVisaCoverOptionsTable from '../Tables/NonWorkingVisaCoverOptionsTable';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';

const NonWorkingVisaCoverOptions = () => {
  return (
    <main style={{width: '100%', height: 'auto'}} className="text-secondary">
        
        <Card className="text-center">
            {/* <Card.Header className='p-5'>
                <h2 className='title'>Non Working Visa Cover Options</h2>
            </Card.Header> */}
            <Card.Body className='p-5'>                
                <Card.Text>
                
                <h6 style={{backgroundColor: 'black', color: 'white', fontWeight: 'bolder', padding: '1em', boxShadow: '1.5px 1.5px 1.5px red', border: 'none', borderRadius: '8px'}}>Non Working Subclass (BVA, BVB, BVC, 590, 870)</h6>
                <NonWorkingVisaCoverOptionsTable/>
                 
                  
                </Card.Text>
            </Card.Body>
            <Card.Footer className="text-muted p-5 mb-0"></Card.Footer>

            
    </Card>
    </main>
  )
}

export default NonWorkingVisaCoverOptions
