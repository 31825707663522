import React, {useState} from 'react'

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import HomeCarousel from './HomeCarousel';
import AccordionHome from '../Accordions/AccordionHome';
import FixedSideBar from '../FixedSideBar';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Accordion from 'react-bootstrap/Accordion';
import logo from '../../images/policydirectlogo.png'
import './TouristVisa.css';

import './Home.css';
import ContactUsModal from '../Modals/ContactUsModal';
const PartnerVisa = () => {
  //modal js
 
  const [showModal, setShowModal] = useState(false);

  const openContactModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  //
  return (
    <>
    {/* <FixedSideBar/> */}
    
    <main style={{width: '100%', height: 'auto'}} className="text-secondary">
        
        <Card className="text-center">
            {/* <Card.Header className='p-5' style={{marginTop: '0', marginBottom: '0', paddingTop: '0', paddingBottom: '0'}}>
                <h4 style={{marginTop: '0', marginBottom: '0', paddingTop: '0', paddingBottom: '0'}}>Policy Direct Australia</h4>
            </Card.Header> */}
            <Card.Body className='p-5'>                
                <Card.Text>               
                <h6 style={{color: 'black'}}>Partner Visa (subclass 300, 301/100, 820/801)</h6>
                <p style={{color: 'black', textAlign: 'left'}}>
                <strong>Partner Visa (subclass 300, 301/100, 820/801) </strong><br/>

               <strong>The Partner (Provisional and Migrant visa) - subclass 309 and 100</strong> allows the individuals to live in Australia who are a spouse or de facto partner of an either Australian citizen, permanent resident, or an eligible New Zealand citizen.
                <br/><br/>
                The provisional 309 visa is a temporary visa while the migrant 100 visa is a permanent visa. The temporary provisional 309 visa is granted first, which allows the applicant to stay in Australia for two years and apply for the permanent – subclass 100 visa within that duration.
                <br/>
                You can also visit:- <a href='https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/partner-offshore/provisional-309'>https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/partner-offshore/provisional-309</a> to know the eligibility requirements for family members.
                <br/><br/>
                <strong>The Partner visas (subclass 820 and 801)</strong> allow the individuals to live in Australia who are spouse or a de facto partner of an Australian citizen, Australian permanent resident, or eligible New Zealand citizen.
                <br/><br/>
                The temporary partner 820 visa is granted first and allows the applicant to live in Australia for two years. During this period, the applicants can apply for the permanent 801 visa if they wish to live in Australia permanently.
                <br/><br/>
                <strong>The prospective marriage 300</strong> visa is granted to people who are engaged and want to get married to an Australian citizen, Australian Permanent Resident, or eligible New Zealand citizen.
                <br/>
                The visa allows applicant to stay in Australia for 9 months and apply for Partner Visa (subclass 820/801) within that period.
                <br/><br/>
                <strong>
                You can join Facebook conversation on Partner visas (subclass 300, 301/100, 820/801) at : <a href='https://www.facebook.com/groups/469691511604972'>https://www.facebook.com/groups/469691511604972</a>
                </strong>
                </p>
                <h6 style={{color: 'black'}}>Why Aussie New Zea Migration?</h6>
                <p style={{color: 'black', textAlign: 'left'}}>
                Aussie New Zea Migration can guide you in the right direction. We have an extensive experience of filling up applications with appropriate documents without any error. This saves a lot of your time and energy. You can contact us or {/* <a href='/contactus'>Request a call back</a>. */}
                <br/><br/>
                <Button onClick={openContactModal} className="blinking-btn" size='sm' style={{backgroundColor: "#064CAC", boxShadow: '1px 1px 1px #EC373C', color: '#EC373C', border: 'none'}}><a style={{textDecoration: 'none', color: 'whitesmoke'}}><strong>Request a Call Back</strong></a></Button>              

                <br/><br/>
                <strong>Disclaimer: </strong> ‘Aussie New Zea Migration & Education Consultancy’ and its associates are independent consulting entities which are not associated in anyway with the Australian ‘Department of Home Affairs’ (DOHA). Information on this website does not constitute personal migration advice. For a customized migration advice based on your personal circumstances, please <a href="tel:+61479070270">call</a> and talk to one of our Immigration Consultants or register your interest with our Associates.

                </p>
              
            </Card.Text>
            </Card.Body>
            <Card.Footer className="text-muted p-5 mb-0"></Card.Footer>

            
    </Card>
    <ContactUsModal
        showModal={showModal} 
        closeModal={closeModal} 
      />
    </main>

    </>
  )
}

export default PartnerVisa
