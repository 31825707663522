import React, {useRef, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
// import { contactUs } from '../../actions/contactmessages.js';
import emailjs from '@emailjs/browser';
import './ContactForm.css';
import './BlinkingButton.css';

const ContactForm = ({showError, setShowError, errorHandler, setErrorHandler}) => {

  const [message, setMessage] = useState(null); 

    const [contactData, setContactData] = useState({
      //firstName: '',
      //lastName: '',
      fullName: '',
      mobile: '',
      email: '',
      message: ''

    });
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmitContact = async (e) => {
      e.preventDefault();
        console.log(contactData)
      /* dispatch(contactUs(contactData, setMessage, navigate)) */
      

      emailjs.sendForm('service_da6rivi', 'template_kjrx28q', form.current, '5mCJhaJUWGKCrjosx')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text)
      });

      setContactData({
        /* firstName: '',
        lastName: '', */
        fullName: '',
        mobile: '',
        email: '',
        message: ''
      })  
 
    }

    const form = useRef();
  return (
    <>

    
        {message}
        <Form ref={form} onSubmit={handleSubmitContact} className='contact-form' /* style={{marginLeft: '25%', marginRight: '25%'}} */>
        <Form.Group className="mb-3 form-group" controlId="formBasicEmail">
       {/*  <Form.Label>First Name</Form.Label> */}
        <Form.Control 
            className='form-control'
            size='sm'
            type="text"
            placeholder="Full Name..." 
            required
            name='fullName'
            value={contactData.fullName}
            onChange={(e) => setContactData({...contactData, fullName: e.target.value})}
            />        
        </Form.Group>
        {/* <Form.Group className="mb-3" controlId="formBasicEmail">
        
        <Form.Control 
            type="text"
            placeholder="Enter Last Name..." 
            required
            name='lastName'
            value={contactData.lastName}
            onChange={(e) => setContactData({...contactData, lastName: e.target.value})}
            />        
        </Form.Group> */}
        <Form.Group className="mb-3" controlId="formBasicEmail">
        
        <Form.Control 
            size='sm'
            type="tel"
            placeholder="Mobile Number..." 
            required
            name='mobile'
            value={contactData.mobile}
            onChange={(e) => setContactData({...contactData, mobile: e.target.value})}
            />        
        </Form.Group>       

        <Form.Group className="mb-3" controlId="formBasicEmail">
            
        <Form.Control
            size='sm' 
            type="email" 
            placeholder="Email..." 
            required
            name='email'
            value={contactData.email}
            onChange={(e) => setContactData({...contactData, email: e.target.value})}

            />            
        </Form.Group>  
        <Form.Group className="mb-3" controlId="formBasicEmail">            
        <Form.Control
            size='sm' 
            as="textarea"
            rows="3"            
            placeholder="Write Message..." 
            required
            name='message'
            value={contactData.message}
            onChange={(e) => setContactData({...contactData, message: e.target.value})}

            />            
        </Form.Group>     
      
        <Button className="blinking-btn" size='sm' style={{backgroundColor: '#25D366', color: 'white', border: 'none'}} type="submit">
          Submit
        </Button>
    </Form>
    </>
  )
}

export default ContactForm