import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './OverseasStudentHealthCoverPageCss.css'
import Button from 'react-bootstrap/Button';

const OverseasStudentHealthCoverPage = () => {
  return (
    <>
        <Container>
        <Row>
          <Col>
            <div className='oshc-div-with-background-image' >
                <br/><br/><br/><br/>
                <h1 style={{color: '#000080'}}>Overseas Student Health Cover</h1>
                <br/><br/>
                <h1 style={{color: '#000080'}}>
                    Join one of Australia's largest OSHC Providers
                </h1>
                <br/><br/>
                <a href='https://api.allianz.com/myquote/1?groupId=56437'>
                <Button variant="danger">Get A Price</Button>
                </a>

            </div>
            <br/>
          </Col>
        </Row>
        </Container>          

    </>    
    )
}

export default OverseasStudentHealthCoverPage