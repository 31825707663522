export const START_LOADING = 'START_LOADING';

export const END_LOADING = 'END_LOADING';

export const SEND_CONTACT_MESSAGE = 'SEND_CONTACT_MESSAGE';

export const WRITE_QUOTE = 'WRITE_QUOTE';

export const SEND_OTP = 'SEND_OTP';

export const AUTH = 'AUTH';

export const AUTH_LOGIN = 'AUTH_LOGIN';

export const MATCH_OTP_AND_SIGN_IN = 'MATCH_OTP_AND_SIGN_IN';

export const VERIFY_OTP = 'VERIFY_OTP';

export const VERIFY_OTP_FORGOT_PASSWORD = 'VERIFY_OTP_FORGOT_PASSWORD';

export const GET_ALL_USERS = 'GET_ALL_USERS';

export const GET_SINGLE_USER = 'GET_SINGLE_USER';

export const ALL_MESSAGES = 'ALL_MESSAGES';

export const REGISTRATION = 'REGISTRATION';

export const LOGOUT = 'LOGOUT';



