import React from 'react'
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { TiTick} from "react-icons/ti";
import { RxCross2 } from "react-icons/rx";
import { VscCheck } from "react-icons/vsc";
import { RxCross1 } from "react-icons/rx";
import allianzCareLogo from '../../images/allianzcarelogo.png'
import bupaLogo from '../../images/bupalogo.png'
import medibankLogo from '../../images/medibanklogo.png'
import nibLogo from '../../images/nibLogo.png'
import { ImCheckmark } from "react-icons/im";
import { ImCross } from "react-icons/im";

const WorkingVisaCoverOptionsTable = () => {
  return (
    <>
        <div>
            <Table responsive="sm">
        <thead>
          <tr style={{backgroundColor: 'red', color: 'black',  fontSize: '.8em'}}>
           
          <th>Name</th>
            <th>Meets Condition (8501)</th>
            <th>Hospital Cover</th>
            <th>Emergency Ambulance</th>
            {/* <th>Pregnancy Cover</th> */}
            <th>GP Visits</th>
            <th>Medical Tests</th>
            <th>Prescribed Medicines</th>
            <th>Price
              <br/> 
              (Per Month Per Person)
            </th>
            <th>Get Quote</th>
            
          </tr>
        </thead>
        <tbody>
        <tr>
        <td colSpan={9}><h6 style={{backgroundColor: 'red', color: 'white', padding: '.4em', border: 'none', borderRadius: '8px'}}>Allianz Care Australia Budget Working Cover</h6></td>

        </tr>
          <tr>
            
            <td>
            <a /* style={{marginRight: '1em', marginBottom: '1em'}} */ href='https://payment.ovhcallianzassistance.com.au/en/?agencyID=856437&groupCodeID=A600'>          
                    <img
                          src={allianzCareLogo}
                          width="110px"
                          height="36px"
                          style={{borderRadius: "10px", zIndex: '1'}}
                      />
            </a>
            </td>
            <td><ImCheckmark style={{fontSize: '1.4em', color: 'green'}}/></td>
            <td><ImCheckmark style={{fontSize: '1.4em', color: 'green'}}/></td>
            <td><ImCheckmark style={{fontSize: '1.4em', color: 'green'}}/></td>

            <td><ImCross style={{fontSize: '1.3em', color: 'red'}}/></td>            
            <td><ImCross style={{fontSize: '1.3em', color: 'red'}}/></td>            
            <td><ImCross style={{fontSize: '1.3em', color: 'red'}}/></td>            

            <td>$77.25</td>
            <td><a href="https://payment.ovhcallianzassistance.com.au/en/?agencyID=856437&groupCodeID=A600">Get Quote</a></td>
          </tr> 
                    

        <tr>
        <td colSpan={9}><h6 style={{backgroundColor: 'red', color: 'white', padding: '.4em', border: 'none', borderRadius: '8px'}}>Allianz Care Australia Standard Working Cover</h6></td>

        </tr>
          <tr>            
            <td>
            <a /* style={{marginRight: '1em', marginBottom: '1em'}} */ href='https://payment.ovhcallianzassistance.com.au/en/?agencyID=856437&groupCodeID=A600'>          
                    <img
                          src={allianzCareLogo}
                          width="110px"
                          height="36px"
                          style={{borderRadius: "10px", zIndex: '1'}}
                      />
            </a>
            </td>
            <td><ImCheckmark style={{fontSize: '1.4em', color: 'green'}}/></td>
            <td><ImCheckmark style={{fontSize: '1.4em', color: 'green'}}/></td>
            <td><ImCheckmark style={{fontSize: '1.4em', color: 'green'}}/></td>
            <td><ImCheckmark style={{fontSize: '1.4em', color: 'green'}}/></td>
            <td><ImCheckmark style={{fontSize: '1.4em', color: 'green'}}/></td>
            <td><ImCheckmark style={{fontSize: '1.4em', color: 'green'}}/></td>

            <td>$113.85</td>
            <td><a href="https://payment.ovhcallianzassistance.com.au/en/?agencyID=856437&groupCodeID=A600">Get Quote</a></td>
          </tr> 
                    

                   


        <tr>
        <td colSpan={9}><h6 style={{backgroundColor: 'red', color: 'white', padding: '.4em', border: 'none', borderRadius: '8px'}}>Bupa</h6></td>
        </tr>
          <tr>            
            <td>
            <a href='https://bupa.com.au/migration-agents/iodnqjq5yeesnvze4iyaug'>          
                    <img
                          src={bupaLogo}
                          width="110px"
                          height="36px"
                          style={{borderRadius: "10px", zIndex: '1'}}
                      />
                    </a> 
            </td>
            <td><ImCheckmark style={{fontSize: '1.4em', color: 'green'}}/></td>
            <td><ImCheckmark style={{fontSize: '1.4em', color: 'green'}}/></td>
            <td><ImCheckmark style={{fontSize: '1.4em', color: 'green'}}/></td>
            <td><ImCheckmark style={{fontSize: '1.4em', color: 'green'}}/></td>
            <td><ImCheckmark style={{fontSize: '1.4em', color: 'green'}}/></td>
            <td><ImCheckmark style={{fontSize: '1.4em', color: 'green'}}/></td>
      
            <td>$99.88</td>
            <td><a href="https://bupa.com.au/migration-agents/iodnqjq5yeesnvze4iyaug">Get Quote</a></td>
          </tr> 
          <tr>
        </tr>    

        
        <tr>
        <td colSpan={9}><h6 style={{backgroundColor: 'red', color: 'white', padding: '.4em', border: 'none', borderRadius: '8px'}}>Medibank</h6></td>
        </tr>
          <tr>            
            <td>
            <a href='https://medibank.com.au/bin/medibank/affiliates/?affiliateID=5000003650&affiliateName=AussieNewZeaMigration&EducationConsultancy &productType=visitors&productID=everyday-hospital-medical'>          
                    <img
                          src={medibankLogo}
                          width="110px"
                          height="36px"
                          style={{borderRadius: "10px", zIndex: '1'}}
                      />
                    </a> 
            </td>
            <td><ImCheckmark style={{fontSize: '1.4em', color: 'green'}}/></td>
            <td><ImCheckmark style={{fontSize: '1.4em', color: 'green'}}/></td>
            <td><ImCheckmark style={{fontSize: '1.4em', color: 'green'}}/></td>
            <td><ImCheckmark style={{fontSize: '1.4em', color: 'green'}}/></td>
            <td><ImCheckmark style={{fontSize: '1.4em', color: 'green'}}/></td>
            <td><ImCheckmark style={{fontSize: '1.4em', color: 'green'}}/></td>
            <td>$73.00</td>
            <td><a href="https://medibank.com.au/bin/medibank/affiliates/?affiliateID=5000003650&affiliateName=AussieNewZeaMigration&EducationConsultancy&productType=WORKER&productID=advanced-hospital-medical&policy">Get Quote</a></td>
          </tr> 
          <tr>
        </tr>  

        <tr>
        <td colSpan={9}><h6 style={{backgroundColor: 'red', color: 'white', padding: '.4em', border: 'none', borderRadius: '8px'}}>NIB</h6></td>
        </tr>
          <tr>            
            <td>
            <a href='http://www.nib.com.au/overseas-working-visitors/new/join/custom?source=44797&type=W'>          
                    <img
                          src={nibLogo}
                          width="110px"
                          height="36px"
                          style={{borderRadius: "10px", zIndex: '1'}}
                      />
                    </a> 
            </td>
            <td><ImCheckmark style={{fontSize: '1.4em', color: 'green'}}/></td>
            <td><ImCheckmark style={{fontSize: '1.4em', color: 'green'}}/></td>
            <td><ImCheckmark style={{fontSize: '1.4em', color: 'green'}}/></td>
            <td><ImCross style={{fontSize: '1.3em', color: 'red'}}/></td>            
            <td><ImCross style={{fontSize: '1.3em', color: 'red'}}/></td>            
            <td><ImCross style={{fontSize: '1.3em', color: 'red'}}/></td>            

               <td>$67.17</td>
            <td><a href="http://www.nib.com.au/overseas-working-visitors/new/join/custom?source=44797&type=W">Get Quote</a></td>
          </tr> 
          <tr>
        </tr>                                            
        </tbody>
      </Table>      
      
      </div>     

    </>
  )
}

export default WorkingVisaCoverOptionsTable
