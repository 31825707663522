import React, {useState} from 'react'

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import HomeCarousel from './HomeCarousel';
import AccordionHome from '../Accordions/AccordionHome';
import FixedSideBar from '../FixedSideBar';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Accordion from 'react-bootstrap/Accordion';
import logo from '../../images/policydirectlogo.png'


import './TravelInsurancePage.css';
import './TouristVisa.css';
import ContactUsModal from '../Modals/ContactUsModal';
import backgroundImage from '../../images/travelinsurancepagebackground.jpg';


const TravelInsurancePage = () => {

  //modal js
 
  const [showModal, setShowModal] = useState(false);

  const openContactModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <>
    {/* <FixedSideBar/> */}
    
    <main style={{width: '100%', height: 'auto'}} className="text-secondary">
        
        <Card className="text-center">
            {/* <Card.Header className='p-5' style={{marginTop: '0', marginBottom: '0', paddingTop: '0', paddingBottom: '0'}}>
                <h4 style={{marginTop: '0', marginBottom: '0', paddingTop: '0', paddingBottom: '0'}}>Policy Direct Australia</h4>
            </Card.Header> */}
            <Card.Body className='p-5'>                
                <Card.Text>
                <div className = "head-text">
                    <div className = "head-image">
                    <img
                    src={backgroundImage}
                    style={{width: '100%', height: '500px'}}
                    />
                    </div>
                    <div class='text-on-image'>
                        <h3> <a href='https://www.covermore.com.au/?utm_source=commission-factory&utm_medium=affiliate&cfclick=19bc52d285de403fa5a0a6ef671f79b1'>Get Quote</a> </h3>                        
                    </div>
                </div>
                
                
                {/* <h6 style={{color: 'black'}}>Get Quote</h6> */}
                
            </Card.Text>
            </Card.Body>
            <Card.Footer className="text-muted p-5 mb-0"></Card.Footer>

            
    </Card>
    
    </main>

    </>
  )
}

export default TravelInsurancePage
