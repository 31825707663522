import React, {useState, useEffect} from 'react'
import './Home.css'
import ContactForm from '../Forms/ContactForm';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

const ContactUs = ({showError, setShowError, errorHandler, setErrorHandler, message, setMessage}) => {
  return (
    <main style={{width: '100%', height: 'auto'}} className="text-secondary">
        
        <Card className="text-center">
            {/* <Card.Header className='p-5'>
                <h2 className='title'>Request Call Back</h2>
            </Card.Header> */}
            <Card.Body className='p-5'>
              
              <h5 style={{color: 'black', textDecoration: 'underline red'}}>Request a Call Back</h5><br/>
              <ContactForm
                showError={showError}
                setShowError={setShowError}
                errorHandler={errorHandler}
                setErrorHandler={setErrorHandler}
              />
            </Card.Body>
            {/* <Card.Footer className="text-muted p-5">2 days ago</Card.Footer> */}
    </Card>
    </main>
  )
}

export default ContactUs
