import React from 'react'
import { BiCaretDown } from "react-icons/bi";
import './Dropdown.css'
const Dropdown = () => {
    
  return (
    <>
        <div className="dropdown tab-item custom-dropdown text-dark nav-dropdown">
            <button className="dropbtn"><strong>Visa Services<BiCaretDown style={{fontSize: '1.5em'}}/></strong></button>
            <div className="dropdown-content">
            <strong>
              <a href='/touristvisa' className="tab-item font-size"  style={{color: '#3B5998'}}>Tourist Visa</a>
              </strong>
              <strong>
              <a href='/touristtostudentvisaconversion' className="tab-item font-size"  style={{color: '#3B5998'}}>Tourist to Student Visa conversion</a>

              </strong>
              <strong>
              <a href='/studentvisa' className="tab-item font-size"  style={{color: '#3B5998'}}>Student Visa (subclass 500)</a>

              </strong>
              <strong>
              <a href='/studentdependentvisa' className="tab-item font-size" style={{color: '#3B5998'}}>Student Dependent Visa (subclass 500)</a>

              </strong>
              <strong>
              <a href='/temporarygraduatevisa' className="tab-item font-size" style={{color: '#3B5998'}}>Temporary Graduate Visa (subclass 485)</a>

              </strong>
              <strong>
              <a href='/skilledvisas' className="tab-item font-size" style={{color: '#3B5998'}}>Skilled Visas (subclass 189, 190, 491)</a>

              </strong>
              <strong>
              <a href='/partnervisa' className="tab-item font-size" style={{color: '#3B5998'}}>Partner Visa (subclass 300, 301/100, 820/801)</a>

              </strong>
              <strong>
              <a href='/parentvisas' className="tab-item font-size"  style={{color: '#3B5998'}}>Parent Visas (subclass 143/173, 884/864, 804)</a>

              </strong>
              <strong>
              <a href='/sponsoredparentvisa' className="tab-item font-size"  style={{color: '#3B5998'}}>Sponsored Parent Visa (subclass 870)</a>

              </strong>

            </div>
        </div>

    </>
  )
}

export default Dropdown
